import {Controller} from "stimulus"


export default class extends Controller {
    static targets = ["template"];

    connect() {
        console.log('loading multiCardSelect controller')
    }

    clickCard(e) {
        console.log('card clicked');
        const card = e.currentTarget.querySelector(".mid-result")
        const checkbox = card.querySelector(".card-selected");
        if (checkbox.checked) {
            checkbox.checked = false;
            card.classList.remove('selected')
        } else {
            checkbox.checked = true;
            card.classList.add('selected');
        }
    }

}
