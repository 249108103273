import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("hello from reports-license_activity");
    }

    updateReport(e) {
        console.log("report changed")
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            const dateFrom = document.getElementById("date_from")
            const dateTo = document.getElementById("date_to")
            if (dateFrom.value != "" && dateTo.value != "" || dateFrom.value == "" && dateTo.value == "") {
                document.getElementById("flash-area").innerHTML = ""
                const reportLicenses = document.getElementById("report_type")
                const reportActivity = document.getElementById("activity_type")
                const jurisdiction = document.getElementById("jurisdiction_id")
                const classification = document.getElementById("classification")
                const residency = document.getElementById("residency")
                let title = "License Activity: " + reportActivity.value + " Licenses " + reportLicenses.value + ": "
                if (dateFrom.value != "") {
                    title += dateFrom.value + " to " + dateTo.value;
                } else {
                    title += "Month to Date";
                }
                let reportUrl = 'chart?title=' + title +
                    '&data_source=reports_licenses_activity_path&date_from=' + dateFrom.value +
                    '&date_to=' + dateTo.value + '&report_type=' + reportLicenses.value +
                    '&activity_type=' + reportActivity.value + '&classification=' + classification.value +
                    '&residency=' + residency.value
                if (jurisdiction) {
                    reportUrl = reportUrl + '&jurisdiction_id=' + jurisdiction.value
                }
                window.location.href = reportUrl;
            } else {
                document.getElementById("flash-area").innerHTML = ('<div  class="warn">Please select a date range or leave both fields blank.</div>')
                dateFrom.classList.add('required-field')
                dateTo.classList.add('required-field')
            }

        }, 2500)


    }
}