import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from bl-check-requests controller");
    }

    postButtonState(e) {
        console.log('checking bl check request form readiness')
        const formArea = e.target.closest("#request-refund").querySelector('.bl-request-form');
        const setAmount = formArea.querySelector("#bl_check_request_amount").value;
        if (setAmount !== AutoNumeric.getNumber(formArea.querySelector("#bl_check_request_amount"))) {
            AutoNumeric.set(formArea.querySelector("#bl_check_request_amount"), setAmount);
        }
        const dateSelectPresent = formArea.querySelector("#bl_check_request_when_booked").value.length > 0;
        const memoAmountPresent = AutoNumeric.getNumber(formArea.querySelector("#bl_check_request_amount"));
        const memoSubmitButton = formArea.querySelector(".button");
        memoSubmitButton.disabled = !(dateSelectPresent && memoAmountPresent);
    }

    updateBalance(e) {

    }

    handleCreate(event) {
        console.log("created!")

        const [data, status, xhr] = event.detail;

        if (data.entry) {
            document.querySelector("#new-memo-row").insertAdjacentHTML('afterend', data.entry_html);
            document.querySelector("#balance-line").innerText =
                `Current Balance ${AutoNumeric.format(data.account_total, {
                    currencySymbol: '$'
                })}`;
            if (data.account_total < 0.0) {
                document.querySelector("#request-refund").classList.remove('hide-refund');
            } else {
                document.querySelector("#request-refund").classList.add('hide-refund');
            }
            document.querySelector("#new-memo-row").querySelector("#memo_entry_description").value = "";
            document.querySelector("#new-memo-row").querySelector("#memo_entry_amount").value = "";
            if (document.querySelector("#bl_check_request_amount")) {
                document.querySelector("#bl_check_request_amount").value = data.account_total * -1;
            }
            document.querySelector("#bl-check-request-form").classList.remove("editing");
            document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">Refund Requested</p>";
        } else {
            console.error("Error updating the form");
        }

    }

}
