import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static values = {fcScenario: String}

    connect() {
    }

    loadScenario(e) {
        const destinationScenario = document.getElementById('scenarios').value;
        const destinationGraphType = document.getElementById('graph_type').value;
        window.location.href = "?scenario=" + destinationScenario + "&graph_type=" + destinationGraphType
    }

    closeForms(e) {

        Array.prototype.map.call(document.querySelectorAll(".editing"), (function (element) {
            element.classList.remove("editing");
        }));
    }

    emailExport(e) {
        e.preventDefault();
        const scenarioId = document.getElementById('scenario_id').value
        const forecastId = document.getElementById('forecast_id').value
        console.log("exporting scenario!" + scenarioId);
        fetch("/fc_forecasts/" + forecastId + "/fc_scenarios/" + scenarioId + "/export_csv_mailer", {
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then(response => response.text())
            .then(html => {
                    alert("Export Initiated.  It will be emailed to you.")
                }
            );

    }
}
