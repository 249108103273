import { Controller } from "stimulus"
import {loadModules} from "esri-loader";


export default class extends Controller {
	static targets = ["template"];
	connect() {
		// load version 4.11: no errors, but nothing renders
        // const options = { css: true };
		//
        // loadModules(['esri/Map','esri/views/MapView'], options)
        //     .then(([Map, MapView]) => {
        //         // the styles, script, and modules have all been loaded (in that order)
        //         console.log("building Map");
        //         const mapAarea=function(Map, MapView){
        //             var map = new Map({
        //                 basemap: "topo-vector"
        //             });
		//
        //             var view = new MapView({
        //                 container: "inspectionMap2",
        //                 map: map,
        //                 center: [-118.71511,34.09042],
        //                 zoom: 11
        //             });
        //         }
		//
        //     })
        //     .catch(err => {
        //         // handle any script or module loading errors
        //         console.error(err);
        //     });

		// load esri-loader version 3.28: no errors, and loads, but when there are more than a few maps rendered it hangs the tab (too much memory)
		// const options = { css: true,  version: '3.28'  };
		//
        // loadModules(['esri/map'], options)
        //     .then(([Map]) => {
        //         // create map with the given options at a DOM node w/ id 'mapNode'
        //         let map = new Map('inspectionMap2', {
        //             center: [-118, 34.5],
        //             zoom: 8,
        //             basemap: 'dark-gray'
        //         });
        //     })
        //     .catch(err => {
        //         // handle any script or module loading errors
        //         console.error(err);
        // });
	}



}
