import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["searchResults", "searchItem"]

    connect() {
        console.log("log from utilityDeviceCertificationMidResult controller");


    }

    openForm(event) {
        console.log('openForm triggered')
        event.target.parentElement.parentElement.querySelector('.quick-form-area').className = "quick-form-area editing"
    }

    closeForm(event) {
        console.log('closeForm triggered')
        event.target.closest('.mid-result').querySelector('.tested-by').style.display='block'
        event.target.closest('.mid-result').querySelector('.certified-on').style.display='block'
        event.target.closest('.mid-result').querySelector('.certify-by').style.display='block'
        event.target.closest('.quick-form-area').className = "quick-form-area"
    }

    processTest(event) {
        console.log('closing the test');
        const card = event.target.closest(".mid-result");
        card.className = "mid-result transition-" + this.data.get("result");
        setTimeout(function () {
            card.style.display = "none";
        }, 1000)
        card.querySelector('.tested-by').style.display='block'
        card.querySelector('.certified-on').style.display='block'
        card.querySelector('.certify-by').style.display='block'

    }

    passForm(event) {
        console.log('passForm triggered')
        this.data.set("result", 'pass')
        event.target.closest('.mid-result').querySelector('#utilities_device_certification_status').value = "Completed"
        event.target.closest('.mid-result').querySelector('.certify-by').style.display='none'

    }

    failForm(event) {
        console.log('failForm triggered')
        this.data.set("result", 'fail')
        event.target.closest('.mid-result').querySelector('#utilities_device_certification_status').value = "Notified"
        event.target.closest('.mid-result').querySelector('.certify-by').style.display='none'
    }

    extendForm(event) {
        console.log('extendForm triggered')
        event.target.closest('.mid-result').querySelector('#utilities_device_certification_status').value = "Notified"
        event.target.closest('.mid-result').querySelector('.tested-by').style.display='none'
        event.target.closest('.mid-result').querySelector('.certified-on').style.display='none'
    }
}
