import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from inspections_index controller");

    }

    getDelayedReport(e) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            reloadReport();
        }, 2100)

    }


    getReport(e) {
        reloadReport();
    }

    getInspection(e) {
        const inspectionRow = e.target.closest('tr');
        const inspectionId = inspectionRow.dataset.id;
        Array.from(document.querySelectorAll('tr')).map(el => {
            el.classList.remove('selected')
        });
        inspectionRow.classList.add('selected');
        fetch("/inspections/" + inspectionId + "/edit?quick_form=true")
            .then(response => response.text())
            .then(html => {
                const inspectionForm = document.querySelector('.inspection-quick-form');
                inspectionForm.innerHTML = html;
                inspectionForm.querySelector('.bl-contacts-container').classList.add('hidden');
            });
    }

    inspectionSaved(e) {
        console.log("Inspection saved!");
        const data = JSON.parse(e.detail[0]);
        document.getElementById("flash-area").innerHTML = "<p class=\"notice\"> Inspection Saved.</p>";
        document.getElementById(data.id).innerHTML = data.row;
    }

}

function reloadReport() {
    const active = document.getElementById('active').checked;
    const unassigned = document.getElementById('unassigned').checked;
    const selectedParcelDoms = document.querySelector('.selected-parcels').querySelectorAll('.result-parcel');
    const selectedParcels = Array.from(selectedParcelDoms).map(parcel => parcel.id);
    const selectedAssignees = Array.from(document.getElementById('assignees')
        .querySelectorAll(':checked')).map(option => option.value)
    console.log("finding tasks for report ");
    window.location.href = "/inspections?active=" + active + "&parcels=" + encodeURIComponent(selectedParcels) +
        "&unassigned=" + unassigned + '&assignees=' + encodeURIComponent(selectedAssignees);
}
