import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"

let numberElements;


export default class extends Controller {

    connect() {
        console.log("log from bl-license_applications index controller");
    }

    updateReportFilter(e) {
        console.log("updating_report")
        const jurisdiction = document.getElementById('jurisdiction').value;
        const licenseTypes = Array.from(document.getElementById('license_types').querySelectorAll(':checked')).map(option => option.value);
        window.location.href = (window.location.pathname + window.location.search).replace(/&jurisdiction=\d*/, '') +
            '&jurisdiction=' + jurisdiction + '&license_types=' + licenseTypes;

    }


}
