import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from reports-license-by-type");
    }

    updateReport(e) {
        console.log("report changed")
        const jurisdiction = document.getElementById("jurisdiction_id")
        const title = 'Active Licenses - By Type';
        window.location.href = 'chart?title=' + title +
            '&data_source=reports_licenses_by_type_path&jurisdiction_id=' + jurisdiction.value;
    }
}