import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from crLegacyCheck controller");

    }

    payReadinessCheck(e) {
        const readySetting = e.target.closest(".check-payment-fields").querySelector(".ready-to-pay");
        if (e.target.value == null || e.target.value == "") {
            readySetting.innerHTML = "false"
        } else {
            readySetting.innerHTML = "true"

        }
    }

}
