import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from parcel-overlay-form controller");

    }

    updateFormFields(e) {
        const form = e.target.closest('form');
        const overlayTypeId = e.target.value
        let link = 'update_fields?parcel_overlay_type_id=' + overlayTypeId
        console.log("updating form to Overlay Type" + overlayTypeId)
        fetch(link)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.getElementById("template-specific-fields").innerHTML = html;
            })
    }


}
