import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("popupMenu");

    }

    closeMenu(event) {
        console.log("Closed?");
        event.target.closest(".popup-menu").classList.remove("editing");
    }

    openMenu(event) {
        event.preventDefault();
        console.log("Opened?");
        event.target.closest(".popup-parent").querySelector(".popup-menu").classList.add("editing");
    }


}

function labelDisplayerEvent(inputEvent) {
}