import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from graphics controller");
        document.querySelector(".image-upload-button").addEventListener("click", function (e) {
            e.preventDefault();
            console.log('image upload requested');
            myWidget.open();
        }, false);
    }

    showGraphic(e) {
        e.preventDefault();
        const graphicId = e.target.closest('.graphic-card').querySelector(".graphic-id").value;
        fetch("/graphics/" + graphicId + "/edit  ", {
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then(response => response.text())
            .then(html => {
                    const displayArea = document.querySelector(".display-area");
                    displayArea.innerHTML = html;
                    displayArea.classList.remove('hidden');

                }
            );

    }

    deleteGraphic(e) {
        console.log("delete graphic");
        let deleteConfirmed = confirm("Are you sure you want to delete this image?");
        if (deleteConfirmed) {
            const graphicId = e.target.closest('.graphic-overlay').querySelector(".graphic-id").value;
            fetch("/graphics/" + graphicId + '.json', {
                method: 'DELETE',
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.text())
                .then(response => {
                    document.querySelector(".graphics-area")
                        .innerHTML = response;
                    document.querySelector(".image-upload-button").addEventListener("click", function () {
                        console.log('image upload requested');
                        myWidget.open();
                    }, false);
                });


        }
    }

    updateGraphic(e) {
        const [data, status, xhr] = e.detail;
        document.querySelector(".graphics-area")
            .innerHTML = xhr.response;
        document.querySelector(".image-upload-button").addEventListener("click", function () {
            console.log('image upload requested');
            myWidget.open();
        }, false);
    }


    closeForm() {
        const displayArea = document.querySelector(".display-area");
        displayArea.classList.add('hidden');

    }

}
