import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from teller_form controller");


    }

    updateCrStation(e) {
        const form = e.target.closest('form')
        const stationPresent = !(document.querySelector("#cr_teller_cr_drawer_id").value == null || document.querySelector("#cr_teller_cr_drawer_id").value == "");
        const drawerPresent = !(document.querySelector("#cr_teller_cr_station_id").value == null || document.querySelector("#cr_teller_cr_station_id").value == "");
        console.log("station change configuration made");
        if (stationPresent && drawerPresent) {
            form.submit();
        }
    }
}
