import {Controller} from "stimulus";

var delayTimer;
export default class extends Controller {
    connect() {
        console.log("tabs controller connected");
    }

    changeTab(e) {
        e.preventDefault();
        console.log("changing tab!");

        // Get the ID of the new tab from the clicked tab or dropdown
        const newTabId = e.target.dataset.tab || e.target.selectedOptions[0].dataset.tab;
        const destinationTab = e.target.closest('.tabs').querySelector(`#${newTabId}`);

        // Hide all tab panels
        e.target.closest('.tabs').querySelectorAll('.tab').forEach(tab => {
            tab.classList.add('hidden');
        });

        // Remove active state from all tab links and set aria-selected to false
        e.target.closest('.tabs').querySelectorAll('.tab-link').forEach(tabLink => {
            tabLink.classList.remove('border-indigo-500', 'text-indigo-600');
            tabLink.classList.add('border-transparent', 'text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700');
            tabLink.setAttribute('aria-selected', 'false');
        });

        // Show the selected tab panel
        destinationTab.classList.remove('hidden');

        // Add active state to the clicked tab link and set aria-selected to true
        e.target.classList.add('border-indigo-500', 'text-indigo-600');
        e.target.classList.remove('border-transparent', 'text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700');
        e.target.setAttribute('aria-selected', 'true');
    }
}
