import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

let autoNumericList = [];

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from cem-contracts controller");
        autoNumericList = new AutoNumeric.multiple(".tendered-field", ['NorthAmerican', {unformatOnSubmit: true}]);
        document.querySelector(".contact-method-select").classList.add('hidden');
        document.querySelector(".contact-method-set").querySelector("h4").innerText = 'Signee Address'
    }

}
