import {Controller} from "stimulus"
import Honeybadger from 'honeybadger-js';

export default class extends Controller {
    static targets = ["cart", "lineItemCharge", "lineItemDescription", "lineItemClass", "lineItemKey", "totalItems", "totalCharge"];


    connect() {
        console.log("connect from miniCartController");
        hideWhenEmpty(this.element);
        hotkeys('ctrl+shift+a', function (event, handler) {
            event.preventDefault();
            console.log('add line item via keystroke');
            addLineItem(amountDue.innerText.trim());
        })
        hotkeys('ctrl+shift+c', function (event, handler) {
            event.preventDefault();
            if (Number(window.window.checkoutMiniCartCount.innerHTML.trim()) > 0) {
                console.log('Checkout!');
            }

        })
    }

    hideWhenEmpty(e) {
        hideWhenEmpty(checkoutMiniCart);
    }

    filterMonth(event) {
        console.log("Month Changed!");
        const resultsList = document.querySelector(".results-list");
        resultsList.innerHTML = '';
        window.location.href = '?month=' + event.target.value
    }

    addItem(event) {
        event.preventDefault();
        const itemReferenceId = document.querySelector("#itemReferenceId");
        const itemReferenceType = document.querySelector("#itemReferenceType");
        const amountDue = document.querySelector("#amountDue");
        addLineItem(amountDue.innerText.trim());
        let data = {
            cart: {
                line_items: [{
                    key: itemReferenceId.innerText.trim(),
                    class: itemReferenceType.innerText.trim(),
                    charge: amountDue.innerText.trim()
                }]
            }
        }
        postCart(data)
    }

    addItemFromDataset(e) {
        e.preventDefault();
        let data = {
            cart: {
                line_items: [{
                    key: e.target.dataset.id,
                    class: e.target.dataset.klass,
                    charge: e.target.dataset.charge
                }]
            }
        }
        postCart(data);
    }
}

function postCart(data) {
    fetch('/carts/add_item', {
        method: 'POST',
        credentials: "same-origin",
        headers: {
            "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    }).then(response => response.json())
        .then((data) => {
            // console.log(data);
            updateCart(data['amount_owed'], data['cart_item_count'])
            hideWhenEmpty(document.getElementById("checkoutMiniCart"));
        })
        .catch((error) => {
                console.warn(error);
                Honeybadger.notify(error)
                document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
            }
        )
}

function updateCart(amountOwed, itemCount) {
    document.getElementById("checkoutMiniCartCartTotal").innerText = '$' + Number(amountOwed).toFixed(2)
    document.getElementById("checkoutMiniCartCount").innerText = itemCount

}

function hideWhenEmpty(component) {
    const numArea = component.querySelector(".circled-number-span");
    if (numArea != null) {
        let itemCount = Number(numArea.innerHTML);
        if (itemCount > 0) {
            numArea.closest(".mini-cart").classList.add("cart-show");
        } else {
            numArea.closest(".mini-cart").classList.remove("cart-show");
        }
    }
}

function addLineItem(lineItemValue) {
    window.checkoutMiniCartCartTotal.innerHTML = "$" + (Number(window.checkoutMiniCartCartTotal.innerHTML.replace("$", "").replace(",", "").trim()) + Number(lineItemValue)).toFixed(2);
    checkoutMiniCartCount.innerHTML = Number(checkoutMiniCartCount.innerText) + 1;
    hideWhenEmpty(document.getElementById("checkoutMiniCart"));

}

