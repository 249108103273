// Form autosave
//
// <%= form_with(model: post, data: { controller: "autosave", autosave_target: "form", action: "turbo:submit-end->autosave#success turbo:fetch-request-error->autosave#error" }) do |form| %>
//   <div class="form-group">
//     <%= form.label :title %>
//     <%= form.text_field :title, class: 'form-control', data: { action: "keyup->autosave#save" } %>
//   </div>
//
//   <div data-autosave-target="status"></div>
// <% end %>

import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['form', 'status']
    static values = {
        submitDuration: {type: Number, default: 1000},
        statusDuration: {type: Number, default: 2000},
        submittingText: {type: String, default: "Saving..."},
        successText: {type: String, default: "Saved!"},
        errorText: {type: String, default: "Unable to save."}
    }

    connect() {
        this.timeout = null
        this.data.set('submitDuration', 500);
        this.data.set('statusDuration', 2000);
        this.data.set('submittingText', "Saving...");
        this.data.set('successText', "Saved!");
        this.data.set('errorText', "Unable to save.");
    }

    save(e) {
        clearTimeout(this.timeout)

        e.preventDefault();
        // AutoNumeric.unformat(document.querySelector("#memo_entry_amount"))
        this.timeout = setTimeout(() => {
            let component = this;
            const form = e.target.form;

            // Get the CSRF token from the meta tag
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            // Create a FormData object from the form
            const formData = new FormData(form);
            this.statusTarget.textContent = this.data.get('submittingText')

            // Submit the form using Fetch API
            fetch(form.action, {
                method: form.method,
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Accept': 'application/js',
                },
                credentials: 'same-origin',
            })
                .then(response => {
                    if (!response.ok) {
                        this.setStatus(this.data.get("errorText"))
                    } else {
                        this.setStatus(this.data.get("successText"))
                    }
                    return response.text();
                })
                .then(data => {

                })
                .catch(error => {
                    console.error('Error during form submission:', error);
                    alert('Failed to set setback. Please try again.');
                });
        }, this.data.get('submitDuration'))


    }

    success() {
        this.setStatus(this.data.get("successText"))
    }

    error() {
        this.setStatus(this.data.get("errorText"))
    }

    setStatus(message) {
        this.statusTarget.textContent = message

        this.timeout = setTimeout(() => {
            this.statusTarget.textContent = ''
        }, this.data.get("statusDuration"))
    }
}