import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from reports license monthly");
    }

    updateReport(e) {
        console.log("report changed")
        const reportYear = document.getElementById("year")
        const reportMonth = document.getElementById("month")
        const jurisdiction = document.getElementById("jurisdiction_id");
        let reportUrl = 'monthly?year=' + reportYear.value + '&month=' + reportMonth.value;
        if (jurisdiction != null) {
            reportUrl = reportUrl + '&jurisdiction_id=' + jurisdiction.value
        }
        window.location.href = reportUrl

    }
}