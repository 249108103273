import {Controller} from "stimulus"


export default class extends Controller {

    connect() {
        console.log("log from bl-sc-portal-export controller");
    }

    updateReportFilter(e) {
        console.log("updating_report")
        const jurisdiction = document.getElementById('jurisdiction').value;
        window.location.href = (window.location.pathname + window.location.search).replace(/\?jurisdiction_id=\d*/, '') + '?jurisdiction_id=' + jurisdiction;
    }


}
