import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from bl_license_type_by_classification controller");
    }

    setClassification(e) {
        const classificationTypeId = e.currentTarget.id;
        const classificationField = e.target.closest(".select-license-type-by-classification").querySelector(".classification-form-id");
        classificationField.value = classificationTypeId;
    }

    setLicenseType(e) {
        console.log("Setting the license type")
        let licenseTypeId;
        const licenseTypeControl = e.target.closest(".bl-license-type-by-classification");
        const classificationLicenseTypeControl = e.target.closest(".selectable-result")
        if (classificationLicenseTypeControl !== null) {
            licenseTypeId = classificationLicenseTypeControl.id;
        } else {
            licenseTypeId = e.target.value;
        }
        const licenseTypeSelect = e.target.closest(".select-license-type-by-classification").querySelector("#bl_license_application_license_type_id")
        e.target.closest(".select-license-type-by-classification").querySelector(".license-type-results").classList.add('hidden');
        // e.target.closest(".select-license-type-by-classification").querySelector(".drop-down-selected-text").innerHTML = e.target.innerText;
        Array.from(licenseTypeSelect.querySelectorAll('option')).map(el => {
            el.removeAttribute("selected")
        })
        licenseTypeSelect.slim.set(licenseTypeId);
        licenseTypeSelect.classList.remove('update-animation')
        licenseTypeSelect.classList.add('update-animation')
        // selectItem.setAttribute('selected', 'selected');
        // licenseTypeSelect.dispatchEvent(new Event('change'));
    }

    updateForm(event) {
        console.log("updating application form")
        const formArea = event.target.closest(".application-area").querySelector('.application-form')
        const licenseTypeId = event.target.closest(".select-license-type-by-classification")
            .querySelector("#bl_license_application_license_type_id").value;
        let applicationId
        if (formArea.querySelector("#bl_license_application_id") !== null) {
            applicationId = formArea.querySelector("#bl_license_application_id").value
        } else if (formArea.querySelector("#itemReferenceId") !== null) {
            applicationId = formArea.querySelector("#itemReferenceId").innerText
        } else {
            applicationId = 'new'
        }
        const licensableId = formArea.querySelector("#bl_license_application_licensable_id").value
        const licensableType = formArea.querySelector("#bl_license_application_licensable_type").value
        const classificationId = event.target.closest(".select-license-type-by-classification").querySelector("#classification_id").value
        if (licenseTypeId.length === 0) {
            console.log("fill in the license Type")
            formArea.querySelector(".feedback-area").innerHTML += '<p class="no-response feedback-text">License Type is missing!</p>';
        } else {
            fetch('/bl_license_applications/render_form?license_type_id=' + licenseTypeId +
                "&license_application_id=" + applicationId +
                "&licensable_id=" + licensableId +
                "&licensable_type=" + licensableType +
                "&classification_id=" + classificationId)
                .then(response => {
                    return response.text();
                })
                .then(html => {
                    if (formArea.querySelector(".no-response") !== null) {
                        formArea.querySelector(".no-response").remove();
                    }
                    formArea.innerHTML = html;
                    document.querySelector(".residency-checkbox").classList.remove('hidden')

                });
        }
    }


}
