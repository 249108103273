import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {

    }

    loadScenario(e) {
        console.log("changing scenario!")
        const destinationScenario = document.getElementById('scenarios').value;
        const destinationGraphType = document.getElementById('graph_type').value;
        window.location.href = "?forecast=" + destinationScenario + "&graph_type=" + destinationGraphType
    }
}
