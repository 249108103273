import {Controller} from "stimulus"
import {validateFields, addressFormSubmittable} from "../../../lib/assets/form-validation";

var delayTimer;
export default class extends Controller {
    static targets = ["results", "queryData"];

    connect() {
        console.log("log from address_external_search controller");

    }

    // search for addresses based on the queryData fields and return results to the results target
    requestSearch(e) {
        clearTimeout(delayTimer);
        var component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = e.target.closest(".search-focus-area").querySelector(".address-search-listing");
            const query = component.targets.findAll('queryData').map(el => {
                return el.value
            }).filter(Boolean).join(",")
            if (query.length > 2) {
                fetch('/search/address_external_query.html?search=' + encodeURIComponent(query) + "&type=address")
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.innerHTML = html;
                    });
            } else {
            }

        }, 800)

    }

    // select an address from the search results and replace the address form with the selected address.
    // If it's an address from an external query and doesn't have an addressId then post the address to /addresses
    selectAddress(e) {
        const addressFormSet = e.target.closest(".address-formset")
        const addressIdField = addressFormSet.parentNode.querySelector(".address-id")
        const addressIdKlass = addressFormSet.parentNode.querySelector(".address-klass")
        let addressId = e.currentTarget.id;
        const chosenAddress = e.currentTarget;
        const chosenAddressArea = addressFormSet.querySelector(".assigned-address-area");
        const addressForm = addressFormSet.querySelector(".address-form-area");
        console.log("click on " + addressId);
        // if addressID is empty then it is a new address.  post the information from currenTarget.dataset to /addresses
        // to create the address and set the addressId to the new id
        if (addressId === "") {
            fetch('/addresses', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(convertKeysToSnakeCase(chosenAddress.dataset))
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    addressIdField.value = data.address.id;
                    if (addressIdKlass) {
                        addressIdKlass.value = data.klass;
                        addressIdKlass.dispatchEvent(new Event('change'));
                    }
                })
        } else {
            addressIdField.value = addressId;
            if (addressIdKlass) {
                addressIdKlass.value = e.currentTarget.dataset.type;
                addressIdKlass.dispatchEvent(new Event('change'));
            }
        }
        addressForm.classList.add("hidden");
        addressForm.querySelectorAll("input").forEach(field => {
            field.classList.remove('required-field')
        })
        if (e.target.closest(".contact-method-fieldset")) {
            e.target.closest(".contact-method-fieldset").querySelectorAll(".contact-submittable").forEach(field => {
                field.value = "true";
            })
            e.target.closest(".contact-method-fieldset").querySelector(".validation-messages").innerHTML = "";
        }
        addressIdField.value = addressId;
        chosenAddressArea.innerHTML = chosenAddress.innerHTML;
        validateFields(addressFormSet);
        addressFormSubmittable(addressFormSet)
        if (e.target.closest(".contact-relation-fieldset")) {
            validateFields(e.target.closest(".contact-relation-fieldset"));
        }
    }


}

function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

function convertKeysToSnakeCase(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(convertKeysToSnakeCase);
    }
    return Object.keys(obj).reduce((acc, key) => {
        const snakeKey = camelToSnakeCase(key);
        acc[snakeKey] = convertKeysToSnakeCase(obj[key]);
        return acc;
    }, {});
}