import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from task-builder controller");


    }

    buildInspection(e) {
        const inspectionType = e.target.value;
        const inspectablePath = e.target.dataset.inspectable;
        window.location.href = inspectablePath + '/inspections/new?inspection_type=' + inspectionType
    }

    buildInspections(e) {
        e.preventDefault();

        const inspectionSet = Array.from(e.target.closest('.inspection-actions').querySelector('.inspection-types').selectedOptions).map(option => {
            return [option.dataset.permitId, option.value]
        });
        const inspectablePath = e.target.dataset.inspectable;
        window.location.href = inspectablePath + '/inspections/new_multiple?inspection_set=' + inspectionSet

    }
}