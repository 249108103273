import { Controller } from "stimulus"

export default class extends Controller {
    connect(){
        console.log("utilities_backflow_devices_form");

    }

    testerSelected(event){
        event.target.parentElement.parentElement.querySelector("ul").innerHTML="";
    }
    calendarFocused(event){
        event.target.parentElement.parentElement.parentElement.querySelector("ul").innerHTML="";
    }


    putCertificationSuccess(event){
        console.log("update_posted")
    }

    closeForm(event){
        event.target.closest(".quick-form-area").classList.remove("editing");

        console.log("Closed?");

    }


}

