import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"


var chargeField;

export default class extends Controller {

    connect() {
        console.log("log from cr-misc-charges controller");
        chargeField = new AutoNumeric(document.querySelector(".cr-adj-amount"), ['NorthAmerican', {unformatOnSubmit: true}]);
    }

    addCharge(e) {
        // e.target.preventDefault();
        // const chargesArea = document.querySelector(".misc-charges-set");
        // console.log("adding Charge")
        // fetch("/cr_misc_charges/add_charge")
        //
        //     .then(response => response.text())
        //     .then(html => {
        //         chargesArea.insertAdjacentHTML("beforeEnd", html);
        //         chargeField.formatOther(chargesArea.lastElementChild.querySelector(".cr-adj-amount"));
        //         allChargesReady();
        //         }
        //     );
    }

    addToCart(e) {
        // if (allChargesReady()) {
        //     const memoSubmitButton = document.querySelector(".charge-checkout-button");
        //     memoSubmitButton.disabled
        //     Array.prototype.map.call(document.querySelectorAll("#cr_misc_charge_amount"), (function (element) {
        //         chargeField.unformatOther(element)
        //     }));
        //     const chargesArea = document.querySelector(".misc-charges-set")
        //     const forms = chargesArea.querySelectorAll("form")
        //     forms.forEach(function (form) {
        //         Rails.fire(form, 'submit');
        //     })
        // } else {
        //     document.querySelector('#flash-area').innerHTML = "<p class=\"notice\"> All Charges Must Be Completed before adding to cart.</p>";
        // }
    }

    chargeSaved(e) {
        console.log("Charge saved!")
    }

    setFieldSetPostable(e) {
        console.log('checking cr misc request form readiness')
        const formArea = e.target.closest('.misc-charge-fieldset');
        const miscTypePresent = formArea.querySelector("#cr_misc_charge_cr_charge_type_id").value;
        const miscAmountPresent = chargeField.unformatOther(formArea.querySelector("#cr_misc_charge_amount").value);
        const miscMemoPresent = formArea.querySelector("#cr_misc_charge_description").value;
        const miscPostReadyField = formArea.querySelector(".post-ready");
        miscPostReadyField.value = (miscMemoPresent && miscAmountPresent && miscTypePresent);
        allChargesReady()
    }

    deleteCharge(e) {
        // const formArea = e.target.closest('.misc-charge-fieldset');
        // formArea.remove();
    }

}

function allChargesReady() {
    const allPostFieldValues = Array.prototype.map.call(document.querySelectorAll(".post-ready"), (function (element) {
        return element.value
    }));
    const allReady = allPostFieldValues.every(isReady);
    if (allReady) {
        document.querySelector('#flash-area').innerHTML = "";
    }
    const memoSubmitButton = document.querySelector(".charge-checkout-button");
    memoSubmitButton.disabled = !(allReady);
    return allReady;
}

function isReady(element, index, array) {
    return element.length > 0;
}
