import {Controller} from "stimulus";
import AutoNumeric from "autonumeric";

let numberElements;
export default class extends Controller {

    connect() {
        console.log("log from audit controller");
        numberElements = new AutoNumeric.multiple([...document.querySelectorAll('.money-cell')], {
            currencySymbol: '$',
            unformatOnSubmit: true
        });
    }


}
