import {Controller} from "stimulus"
import {validateFields, contactFormSubmittable} from "../../../lib/assets/form-validation";

var delayTimer;
export default class extends Controller {

    connect() {
        // console.log("log from contact-relation-form controller");
        contactFormSubmittable()
    }

    addContactMethod(e) {
        let urlParam = "";
        e.preventDefault();
        const crId = document.getElementById('contact_relation_id').value;
        if (crId.length > 0) {
            urlParam = "?cr=" + crId;
        }
        const fieldArea = e.target.closest(".contact-method-set");
        fetch('/contact_methods/add_fields' + urlParam)
            .then(response => response.text())
            .then(html => {
                    fieldArea.insertAdjacentHTML("beforeend", html);
                    let methodSelects = document.querySelectorAll(".method-select");
                    let lastMethodSelect = methodSelects[methodSelects.length - 1];
                    lastMethodSelect.focus();
                }
            );

    }

    contactSelected(e) {
        document.querySelector(".contact-relation-fields-submittable").value = "true";
    }


    errorOnSubmit(e) {
        console.log("Error on Submit of Contact Relation Form!");
        console.log(e);
    }

    validateForm(e) {
        validateFields(e.target.closest(".contact-relation-fieldset"));
        contactFormSubmittable()
    }


}
