import {Controller} from "stimulus"

var delayTimer;
//value of station_filter_type url param or 'cash-station-groups' if not present
var stationFilterType;

export default class extends Controller {

    connect() {
        stationFilterType = new URLSearchParams(window.location.search).get('station_filter_type') || 'cash-station-groups';
    }

    getDelayedReport(e) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            reloadReport();
        }, 2500)

    }

    getReport(e) {
        reloadReport();
    }

    setStationFilterType(e) {
        stationFilterType = e.target.dataset.tab;
    }

}

function reloadReport() {
    const postingDate = document.getElementById('report_date').value;
    const onlineOnly = document.getElementById('online_only').checked;
    const ccOnly = document.getElementById('cc_only').checked;
    const activitySourceControls = document.querySelectorAll('.activity-source')
    const crStationControl = document.getElementById("cr_stations")
    const selectedStations = Array.from(crStationControl.querySelectorAll(':checked')).map(option => option.value)
    const crStationGroupControl = document.getElementById("cr_station_groups");
    const selectedStationGroups = Array.from(crStationGroupControl.querySelectorAll(':checked')).map(option => option.value);
    const crGlAccountControl = document.getElementById("gl_accounts");
    const selectedAccounts = Array.from(crGlAccountControl.querySelectorAll(':checked')).map(option => option.value)
    let activitySources = {}
    Array.from(activitySourceControls).map(el => {
        activitySources[el.id] = el.checked
    })
    window.location.href = "status?report_date=" + postingDate +
        "&online_only=" + onlineOnly +
        "&cc_only=" + ccOnly +
        "&cr_stations=" + encodeURIComponent(selectedStations) +
        "&gl_accounts=" + encodeURIComponent(selectedAccounts) +
        "&activity_sources=" + JSON.stringify(activitySources) +
        "&cr_station_groups=" + encodeURIComponent(selectedStationGroups) +
        "&station_filter_type=" + stationFilterType;

}
