import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from address-merge controller");
    }

    selectSubsumed(e) {
        e.preventDefault();
        console.log("subsumed selected")
        const subsumed = e.target.closest('li').id;
        const current = JSON.parse(e.target.closest('.search-container').querySelector('.address-search-input').dataset.excludedIds);
        const subsumedname = e.target.closest('li').querySelector('.result-primary').innerText
        if (current === subsumed) {
            alert("Existing address cannot be merged into itself.")
            return
        }
        if (subsumed.length > 0) {
            if (confirm('Merge address ' + subsumed + ': ' + subsumedname)) {
                console.log("subsumed selected")
                fetch("/addresses/merge?current=" + current[0] + "&subsumed=" + subsumed)
                    .then(response => response.text())
                    .then(html => {
                            console.log("subsume successful")
                            document.querySelector('.contact-select-area').classList.add("hidden");
                            document.getElementById('flash-area').innerHTML = "<p class=\"notice\">Address Successfully Merged</p>";

                        }
                    );
            } else {
                e.target.closest('.contact-select-area').classList.add("hidden");

            }
        }
    }

}
