import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from signature controller");
        let canvas = document.querySelector('canvas');
        canvas.style.position = 'relative';
        canvas.style.top = "0";
        canvas.style.left = "0";

        let ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 200;

        ctx.lineWidth = 3;
        ctx.lineJoin = ctx.lineCap = 'round';

        let isDrawing, drawLine;

        canvas.onmousedown = function (event) {
            isDrawing = true;
            let rect = canvas.getBoundingClientRect();
            drawLine = {x: event.clientX - rect.left, y: event.clientY - rect.top};
        };

        canvas.onmousemove = function (event) {
            if (!isDrawing) return;
            let rect = canvas.getBoundingClientRect();
            ctx.beginPath();

            ctx.moveTo(drawLine.x, drawLine.y);
            ctx.lineTo(event.clientX - rect.left, event.clientY - rect.top);
            ctx.stroke();

            drawLine = {x: event.clientX - rect.left, y: event.clientY - rect.top};
        };

        canvas.onmouseup = function () {
            isDrawing = false;
        };

        canvas.ontouchstart = function (event) {
            event.preventDefault();
            isDrawing = true;
            let rect = canvas.getBoundingClientRect();
            drawLine = {x: event.touches[0].clientX - rect.left, y: event.touches[0].clientY - rect.top};
        };

        canvas.ontouchmove = function (event) {
            event.preventDefault();
            if (!isDrawing) return;
            let rect = canvas.getBoundingClientRect();
            ctx.beginPath();

            ctx.moveTo(drawLine.x, drawLine.y);
            ctx.lineTo(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top);
            ctx.stroke();

            drawLine = {x: event.touches[0].clientX - rect.left, y: event.touches[0].clientY - rect.top};
        };

        canvas.ontouchend = function () {
            isDrawing = false;
        };

    }


    clear() {
        console.log("clearing signature");
        let canvas = document.querySelector('canvas');
        clearCanvas(canvas);
    }

    save() {
        console.log("saving signature");
        document.querySelector('.signature-notice').classList.add('hidden');
        document.querySelector('.signature-error').classList.add('hidden');
        const canvas = document.querySelector('canvas');
        const dataUrl = canvas.toDataURL("image/png;base64");
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const signableKlass = document.querySelector('#signable_klass').value;
        const signableId = document.querySelector('#signable_id').value;

        fetch('/signatures', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            },
            body: JSON.stringify({
                signature: dataUrl,
                signable_klass: signableKlass,
                signable_id: signableId
            })
        }).then(response => response.json())
            .then(data => {
                    console.log(data);
                    if (data.status === 'success') {
                        document.querySelector('.signature-notice').textContent = 'Signature saved successfully';
                        document.querySelector('.signature-notice').classList.remove('hidden');
                        document.querySelector('.signature-image').src = dataUrl;
                        clearCanvas(canvas);
                    } else {
                        document.querySelector('.signature-error').textContent = 'Error saving signature';
                        document.querySelector('.signature-error').classList.remove('hidden');
                    }
                }
            );
    }


}

function clearCanvas(canvas) {
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}
