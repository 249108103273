import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from cr-deposit controller");

    }

    saveDeposit(e) {
        console.log("saving deposit!")
        e.target.parentElement.submit();
    }
}
