import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"

let numberElements;


export default class extends Controller {

    connect() {
        console.log("log from bl-license-estimates controller");
        const residencyCheckbox = document.querySelector(".residency-checkbox");
        if (residencyCheckbox) {
            residencyCheckbox.classList.remove('hidden');
        }

        const addChargeArea = document.querySelector(".add-charge-area");
        if (addChargeArea) {
            addChargeArea.classList.add('hidden');
        }
    }

    updateBusinessName(event) {
        const businessNameSpan = document.getElementById('estimate-business');
        const businessNameText = document.getElementById('business_name');
        businessNameSpan.innerText = businessNameText.value;
    }

    updateEstimate(event) {
        console.log("updating estimate");
        const revenueField = document.querySelector(".calc-input-primary");
        revenueField.dispatchEvent(new Event('change'));
    }

}


