/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("channels");
require("trix")
require("@rails/actiontext")

// Monitoring:
import LogRocket from 'logrocket';
// import * as Honeybadger from 'honeybadger-js'

console.log("environment is " + process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
    LogRocket.init('zhomkk/govpossible');
}


import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

import Rails from '@rails/ujs';
import '../src/application.scss'
import Chartkick from "chartkick";
import Flatpickr from "stimulus-flatpickr";
import {Autocomplete} from 'stimulus-autocomplete';
import AutoNumeric from 'autonumeric';
import hotkeys from 'hotkeys-js';
import autocomplete from 'autocompleter';
// import {loadStripe} from '@stripe/stripe-js';
window.Chartkick = Chartkick;
// require("@rails/ujs").start();
// require("turbolinks").start();
require("@hotwired/turbo-rails")

window.Rails = Rails
// for Chart.js
import Chart from "chart.js";

Chartkick.addAdapter(Chart);
import StimulusSlimSelect from "stimulus-slimselect"
// global.Rails = Rails;
// Rails.start();


//ESRI Webmap
//couldn't make webpack work
// import {WebMap}  from "ArcGISPlugin";
// import Map from "esri/Map";
// import MapView from "esri/views/MapView";
// have to instantiate the load at the controller as this relies on the HTML body being rendered... which isn't the case when this area runs
import {loadModules} from 'esri-loader';


const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
application.register('slimselect', StimulusSlimSelect)
application.register("flatpickr", Flatpickr);
application.register('autocomplete', Autocomplete);
// if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
//
//     application.handleError = (error, message, detail) => {
//         console.warn(message, detail)
//         Honeybadger.notify(error)
//     };
// }
// document.addEventListener("turbo:load", function() {
//     console.log('Turbo started');
// });

