import {Controller} from "stimulus"
import {contactFormSubmittable, validateFields} from "../../../lib/assets/form-validation";

export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from contact-select controller v2");
    }

    selectContact(e) {
        e.preventDefault();
        e.target.closest(".contact-search-results").classList.remove("showing");
        const chosenContactId = e.currentTarget.id;
        const selectPopup = e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup");
        const selectResultsArea = selectPopup.querySelector(".contact-method-selection-area")
        const selectedContactsArea = e.target.closest(".contact-select-area").querySelector(".selected-contacts")
        console.log('contact selected: ' + chosenContactId);
        fetch("/contact_methods/contact_methods_for_contact.js?contact_id=" + encodeURIComponent(chosenContactId))
            .then(response => {
                return response.text();
            })
            .then(html => {
                // hacky as heck; the contact method list gets returned as an <h3> while the contact without methods
                // gets returned as an <h4>.  Need to retool the controllers if possible to return an hash with the indicator
                // or call .js and have the response deliver the javascript to  handle these changes.
                selectResultsArea.innerHTML = html;
                // contactFormArea.innerHTML = html;
                if (html.substring(1, 3) === 'h3') {
                    selectPopup.classList.add("editing");
                } else {
                    console.log('selected contact has no methods');
                    selectedContactsArea.innerHTML = html;
                    selectedContactsArea.classList.remove("hidden");
                    e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup").classList.remove("editing");
                    this.hideContactArea(e);
                    this.dropLastNameRequirement(e);
                    contactFormSubmittable();
                }
            })
    }

    enableNew(e) {
        e.target.closest(".contact-search-area").querySelector(".add-contact-control").classList.remove("hidden");

    }

    showContactForm(e) {
        e.target.closest(".contact-search-area").classList.add("hidden");
        e.target.closest(".contact-relation-fieldset").querySelector(".contact-form-area").classList.remove("hidden");
        e.target.closest(".contact-relation-fieldset").querySelector(".contact-form-area").querySelector(".start-field").focus();
    }

    addContact(e) {
        e.preventDefault();
        console.log("add contact clicked");
        const selectPopup = e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup");
        const chosenContactId = e.target.dataset.contact;
        const contactMethods = e.target.closest(".contact-select-area").querySelectorAll(".contact-method")
        const selectedContactMethods = Array.from(contactMethods).filter(contactMethod => contactMethod.checked)
        const selectResult = selectPopup.querySelector(".contact-info")
        const selectedContactsArea = e.target.closest(".contact-select-area").querySelector(".selected-contacts")
        const contactMethodIds = selectedContactMethods
            .map(contactMethod => contactMethod.id);
        const selectedContactMethodLabels = selectedContactMethods.map(contactMethod => contactMethod.labels[0].innerText)
        console.log("clicked on " + contactMethods.length + "methods for contact" + chosenContactId)
        selectedContactsArea.appendChild(selectResult);
        // convert the contact method labels to an html list and append it to selectResult
        const selectContactsList = "<ul>" + selectedContactMethodLabels.map(label => {
            return "<li>" + label + "</li>"
        }).join('') + "</ul>";
        let parser = new DOMParser();
        let selectContactsListNode = parser.parseFromString(selectContactsList, 'text/html').body.firstChild;

        selectResult.appendChild(selectContactsListNode);
        selectResult.classList.remove("hidden");
        selectedContactsArea.querySelector("#contact_method_ids").value = contactMethodIds;
        selectedContactsArea.classList.remove("hidden");
        e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup").classList.remove("editing");
        this.hideContactArea(e);
        this.dropLastNameRequirement(e);
        contactFormSubmittable();
    }

    dropLastNameRequirement(e) {
        const contactFormArea = e.target.closest(".contact-form-area");
        if (contactFormArea) {
            contactFormArea.querySelector(".contact-last-name").classList.remove("required-field");
            Array.from(e.target.closest(".contact-form-area").querySelectorAll("#submittable")).map(field => {
                field.value = 'true'
            });
            validateFields(e.target.closest(".contact-relation-fieldset"));
        }
    }

    hideContactArea(e) {
        e.target.closest(".contact-select-area").querySelector(".contact-method-fieldset").classList.add("hidden");
        // e.target.closest(".contact-select-area").querySelector(".search-results-area").classList.add("hidden");

    }
}
