import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    static get targets() {
        return ['optionsWrapper', 'option', 'trigger', 'triggerText', 'value']
    }

    initialize() {
        this.index = 0;
    }

    connect() {
        console.log("dropdown controller");
        if (this.hasValueTarget) {
            this.triggerTarget.setAttribute('aria-expanded', 'false');
            this.optionsWrapperTarget.classList.remove('dropdown__option-wrapper--visible');
            this.triggerTextTarget.innerHTML = this.valueTarget.options[this.valueTarget.selectedIndex].text;
        }
    }

    toggle() {
        event.preventDefault();

        if (this.triggerTarget.getAttribute('aria-expanded') == "false") {
            this.open();
        } else {
            this.close(null);
        }
    }

    open() {
        this.triggerTarget.setAttribute('aria-expanded', 'true');
        this.optionsWrapperTarget.classList.add('dropdown__option-wrapper--visible');
        this.focusOption(0);
    }

    close(event) {
        if (event
            && event.type == 'click'
            &&
            (this.optionTargets.includes(event.target)
                || this.triggerTarget.contains(event.target))) {
            event.preventDefault();
            return;
        }

        this.triggerTarget.setAttribute('aria-expanded', 'false');
        this.optionsWrapperTarget.classList.remove('dropdown__option-wrapper--visible');
    }

    getKey(event) {
        switch (event.code) {
            case 'ArrowDown':
                event.preventDefault();
                this.moveDown();
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.moveUp();
                break;
            case 'Space':
                break;
            default:
                this.close(event);
        }
    }

    moveDown(increment = 1) {
        this.index = this.mod((this.index + increment), this.optionTargets.length);
        this.focusOption(this.index);
    }

    moveUp(increment = -1) {
        this.moveDown(increment);
    }

    // Allows for modulo of negative numbers, so the index can wrap back around.
    mod(dividend, divisor) {
        return ((dividend % divisor) + divisor) % divisor;
    }

    focusOption(index) {
        this.optionTargets[index].focus();
    }

    chooseOption(choice) {
        const choiceValue = choice.target.innerText;
        console.log(choiceValue + " selected");
        const selectItem = choice.target.closest(".dropdown-wrapper").querySelector(".entry-type-select");
        selectItem.value = choice.target.dataset.selectDropDownValue;
        Array.from(selectItem.querySelectorAll('option')).map(el => {
            el.removeAttribute("selected")
        })
        selectItem.querySelector('option[value="' + choice.target.dataset.selectDropDownValue + '"]').setAttribute('selected', 'selected');
        selectItem.dispatchEvent(new Event('change'));
        this.triggerTarget.setAttribute('aria-expanded', 'false');
        this.optionsWrapperTarget.classList.remove('dropdown__option-wrapper--visible');
        this.triggerTextTarget.innerText = choiceValue;
    }
}
