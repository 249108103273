import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from business-merge controller");
    }

    toggleControl(e) {
        console.log("control toggled")
        e.target.closest(".merge-action").querySelector(".business-select-area").classList.toggle("hidden")
    }


    selectSubsumed(e) {
        const subsumed = e.target.closest('a').id;
        const subsumedname = e.target.closest('a').querySelector('.result-primary').innerText
        if (subsumed.length > 0) {
            if (confirm('Merge bz-' + subsumed + ': ' + subsumedname)) {
                console.log("subsumed selected")
                fetch(window.location.href + '/subsume?subsumed=' + subsumed)
                    .then(response => response.text())
                    .then(html => {
                            console.log("subsume successful")
                            document.querySelector('.business-select-area').querySelector(".new-business-form").classList.add("hidden");
                            document.querySelector('.business-select-area').classList.add("hidden");
                            document.getElementById('flash-area').innerHTML = "<p class=\"notice\">Business Successfully Merged</p>";

                        }
                    );
            } else {
                e.target.closest('.business-select-area').classList.add("hidden");
                e.target.closest('.business-select-area').querySelector(".new-business-form").classList.add("hidden");

            }
        }
    }

}
