import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from reportsCashflowPayouts");
    }

    updateReport(e) {
        console.log("report changed")
        const reportDate = document.getElementById("date")
        const title = "Payouts: " + reportDate.value;
        window.location.href = 'chart?title=' + title + '&data_source=reports_cashflow_payouts_path&chart_type=pie_chart&date=' + reportDate.value;
    }
}