import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

////
// keyboard behaviour to allow for auto edit and sve based on tabbing
export default class extends Controller {
    static openForm = null;

    connect() {
        console.log("log from inlineFieldEdit controller");
        this.addTurboFrameLoadListener();
    }

    addTurboFrameLoadListener() {
        this.element.addEventListener('turbo:frame-load', (event) => {
            const frame = event.target;
            if (frame.classList.contains('inline-edit')) {
                const input = frame.querySelector('input, textarea, select');
                if (input) {
                    const form = input.closest('form');
                    if (Controller.openForm && Controller.openForm !== form) {
                        Controller.openForm.requestSubmit();
                    }
                    input.focus();
                    input.select();
                    Controller.openForm = form;
                    console.info('set openform to ', form)
                }
            }
        });
    }

    submitOnTab(e) {
        console.log('typing')
        if (e.key === 'Tab') {
            e.preventDefault();
            console.log("tab, clicked, saving!")
            const form = this.element.closest('form');
            if (form) {
                form.requestSubmit();
                const nextFrame = this.element.closest('.inline-edit-block').nextElementSibling;
                if (nextFrame.classList.contains('inline-edit-block')) {
                    const link = nextFrame.querySelector('a');
                    if (link && link.parentElement.classList.contains('inline-edit')) {
                        console.log("link found, edit next field")
                        link.click();
                    }
                } else {
                    console.log("No more frames to edit");
                    Controller.openForm = null;
                    console.log("set openform to nil")
                }
            }
        }
    }
}