import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from keystroke controller");

    }


}
