import {Controller} from "stimulus";
import AutoNumeric from "autonumeric";

let numberElements;
export default class extends Controller {

    connect() {
        console.log("log from license controller");
        numberElements = new AutoNumeric.multiple([...document.querySelectorAll('.money-cell')], {currencySymbol: '$'});
    }

    selectClassification(e) {
        const licenseId = document.querySelector("#license_id").value;
        const classificationTypeId = e.currentTarget.id;
        const formWindow = e.currentTarget.closest(".popup-form");
        fetch(licenseId + "/update_classification.js?classification_id=" + classificationTypeId)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.querySelector(".license-classification-area").innerHTML = html;
                formWindow.classList.remove("editing");
            })

    }

    calculateAdjustment(e) {
        const licenseId = document.querySelector("#license_id").value;
        const additionalAmount = Number(AutoNumeric.getNumber(e.currentTarget.closest(".lic-calc-fields-area").querySelector(".lic-revenue-field")));
        const adjustmentId = e.currentTarget.closest(".lic-calc-fields-area").querySelector("#bl_license_adjustment_id").value;
        const description = e.currentTarget.closest(".lic-calc-fields-area").querySelector("#bl_license_adjustment_description").value;
        const formArea = e.currentTarget.closest(".license-adjustment-area");
        e.preventDefault();
        console.log("calculating adjustment");
        fetch(licenseId + "/bl_license_adjustments/calculate_adjustment.js?amount=" + additionalAmount + "&id=" + adjustmentId + "&description=" + encodeURIComponent(description))
            .then(response => {
                return response.text();
            })
            .then(html => {
                formArea.innerHTML = html;
                const newAdjustmentField = new AutoNumeric("#bl_license_adjustment_units_adjustment", {currencySymbol: '$'});
                let resultNumber = Number(formArea.querySelector("#amountDue").innerText).toFixed(2);
                const addItemButton = formArea.querySelector(".add-charge-area");
                if (Number(resultNumber) > 0.0) {
                    addItemButton.classList.remove("hidden");

                }
            })

    }

    changeLicenseType(e) {
        const licenseId = document.querySelector("#license_id").value;
        const newLicenseTypeId = e.target.value;
        const newLicenseTypeDescription = e.target.selectedOptions[0].text;
        const licenseTypeLabel = document.querySelector(".legacy-identifier");
        const licenseSelect = document.getElementById('entry_type');
        const formWindow = e.currentTarget.closest(".popup-form");
        console.log("updating license_type: " + newLicenseTypeId + " " + newLicenseTypeDescription)
        fetch(licenseId + '/update_license_type?new_type=' + newLicenseTypeId)
            .then(response => {
                return response.text();
            })
            .then(html => {
                licenseTypeLabel.innerText = newLicenseTypeDescription
                licenseSelect.value = newLicenseTypeId
                formWindow.classList.remove("editing");
            })
    }

}
