import { Controller } from "stimulus"
var delayTimer;

export default class extends Controller {
  static targets = [ "searchResults", "searchItem" ]

  connect() {
    console.log("log from utilityBackflowSearch controller");

  }

  requestSearch(){
      clearTimeout(delayTimer);
      var component = this;
      delayTimer = setTimeout(function () {
          const resultsArea = document.querySelector('.bl-search-results')
          if (component.searchItemTarget.value.length > 2) {
              resultsArea.classList.add("showing")
              fetch('/utilities_backflow_devices/query.html?search=' + encodeURIComponent(component.searchItemTarget.value))
                  .then(response => {
                      return response.text();
                  })
                  .then(html => {
                      resultsArea.innerHTML = html;
                  });
          } else {
              resultsArea.classList.remove("showing")
          }
      },500);
  }


}
