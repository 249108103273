import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";
import {validateFields, formSubmittable} from "../../../lib/assets/form-validation";

var delayTimer;
let autoNumericList;
export default class extends Controller {
    connect() {
        console.log("log from permitting-quick-entry controller");
        // hide unused contact elements for quick entry
        document.querySelector('.contact-type-select').classList.add('hidden');
        document.querySelector('.contact-type-description-field').classList.add('hidden');
        document.querySelector('.contact-method-set').classList.add('hidden');
        document.querySelector('.contact-method-select').classList.add('hidden');
        document.querySelector('.contact-role-select').classList.add('hidden');
        document.querySelector('.parcel-search-input').focus();
        formSubmittable(document.querySelector(".permitting-quick-entry-form"));
    }

    parcelSearchFocus(e) {
        e.target.closest(".parcel-search-container").querySelector(".parcel-search-input").focus();
    }

    showChildPermitBuilder(e) {
        const appTemplateId = e.target.value;
        const childPermitBuilder = document.querySelector(".child-permit-builder");
        const childPermitsList = document.querySelector(".child-permits-list");
        const link = '/permitting/app_templates/' + appTemplateId + '/sub_templates';
        childPermitsList.innerHTML = "";
        console.log("getting children of app Template" + appTemplateId)
        fetch(link)
            .then(response => {
                return response.text();
            })
            .then(html => {
                if (html.length > 0) {
                    childPermitsList.innerHTML = html;
                    childPermitBuilder.classList.remove("hidden");
                }
                validatePermittingQuickEntryForm();
            })
    }

    renderQuickFees(e) {
        const appTemplateId = document.getElementById('permitting_application_permitting_app_template_id').value;
        const quickFeeArea = document.querySelector(".quick-charges");
        const quickFeeList = quickFeeArea.querySelector(".quick-charges-list");
        const formData = new FormData(e.target.closest('form'));
        const link = '/permitting/app_templates/' + appTemplateId + '/quick_charges';
        quickFeeList.innerHTML = "";
        document.getElementById('spinner-container').style.display = 'flex';
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {

            fetch(link, {
                method: "POST",
                body: formData,
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
                }
            })
                .then(response => {
                    return response.text();
                })
                .then(html => {
                    if (html.length > 0) {
                        document.getElementById('spinner-container').style.display = 'none';
                        quickFeeList.innerHTML = html;
                        quickFeeArea.classList.remove("hidden");
                        e.target.closest('form').querySelector('.permitting-quick-entry-form-submittable').value = "true";
                        validatePermittingQuickEntryForm()
                        totalQuickFees()
                    }
                })
        }, 1000)
    }

    offerParcelPayor(e) {
        const parcel = e.target.closest('li')
        if (!(parcel.classList.contains('offered-parcel-payor'))) {
            parcel.classList.add('offered-parcel-payor');
            const payorForm = document.querySelector('.parcel-payor')
            payorForm.querySelector("#parcel_payor_id").value = parcel.id;
            payorForm.querySelector(".parcel-payor-name").innerText = (/\(.*?\)/).exec(parcel.querySelector(".result-primary").innerText)[0];
            payorForm.classList.remove('hidden');
        }
    }

    parcelPayorAccept(e) {
        e.preventDefault();
        const chosenParcel = e.currentTarget.closest('.parcel-payor-confirmation').querySelector("#parcel_payor_id").value;
        fetch("/parcels/" + chosenParcel + "/owner_select")
            .then(response => {
                return response.text();
            })
            .then(html => {
                const selectedContactArea = document.querySelector(".contact-form-area")
                selectedContactArea.innerHTML = html;
                selectedContactArea.classList.remove("hidden");
                // document.querySelector(".contact-search-area").classList.add("hidden");
                e.target.closest(".parcel-payor").classList.add("hidden");
                document.querySelector(".add-payor-address-control").classList.remove("hidden");
            })

    }

    showPayorAddressForm(e) {
        const payorAddressForm = document.querySelector('.parcel-payor-address');
        const rect = e.target.getBoundingClientRect();
        payorAddressForm.classList.add('editing');
        payorAddressForm.style.top = (rect.top + 20) + "px";
        payorAddressForm.style.left = (rect.left - 100) + "px";
    }

    addPayorAddress(e) {
        e.preventDefault();
        // AutoNumeric.unformat(document.querySelector("#memo_entry_amount"))
        let component = this;
        const form = e.target.form;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/html',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.text();
            })
            .then(data => {
                console.log('selecting payor address, need to have this put the address in the right spot to save it.')
                const addressIdField = e.currentTarget;
                document.querySelector('.parcel-payor-address').classList.remove('editing');
                const contactMethodHtml = data
                //wrap contactMethodHtml in a <ul> tag and replace the html in .add-payor-address-control
                const newContactMethod = document.createElement('ul')
                newContactMethod.innerHTML = contactMethodHtml
                document.querySelector('.add-payor-address-control').innerHTML = newContactMethod.outerHTML
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set address. Please try again.');
            });

    }

    feeValidation(e) {
        validatePermittingQuickEntryForm()
        totalQuickFees()

    }

    contactSelected(e) {
        document.querySelector(".add-payor-address-control").classList.remove("hidden");
        document.querySelector(".parcel-payor").classList.add("hidden");
    }

    noChargePermitCheck(e) {
        const feeArea = e.target.closest('.quick-charges-list')
        const fees = feeArea.querySelectorAll('input[type="checkbox"]')
        const chargePresent = Array.from(fees).some(fee => fee.checked)
        const submitButton = document.querySelector(('.quick-entry-submit'))
        if (chargePresent) {
            submitButton.value = 'Save & Add to Cart'
        } else {
            submitButton.value = 'Create No Charge Permit'
        }
    }
}

function validatePermittingQuickEntryForm() {
    if (document.getElementById('estimator').value == 'true') {
        document.getElementById("validation-messages").innerHTML = "";
    } else {
        validateFields(document.querySelector(".permitting-quick-entry-form"));
        if (document.querySelector(".quick-charges").querySelector(".quick-charges-list").childElementCount < 1) {
            document.querySelector('.permitting-quick-entry-form-submittable').value = "false";
            document.getElementById("validation-messages").insertAdjacentHTML("beforeend", "<div class='alert alert-danger'>Fee Must Be Selected</div>");
        }
        formSubmittable(document.querySelector(".permitting-quick-entry-form"));
    }
}

function totalQuickFees() {
    const feeFields = Array.from(document.querySelector(".quick-charges-list").querySelectorAll(':checked'))
    let feeTotal = feeFields.reduce(function (total, element) {
        console.log(total + Number(element.dataset.amount))
        return total + Number(element.dataset.amount);
    }, 0.0)
    document.getElementById("total-charges").innerText = "Total: " + AutoNumeric.format(feeTotal, {
        currencySymbol: '$',
    });
}
