import { Controller } from "stimulus"


export default class extends Controller {
	static targets = ["template"];
	connect() {
		console.log("log from codesInspectionForm controller");
	}



	addViolation(event){
		const violationsArea = document.querySelector('.violationsControlSet');
		const personServed = document.querySelector('#codes_inspection_person_served').value;
		const violationFields = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
		event.preventDefault();
		console.log("violation added");
		violationsArea.insertAdjacentHTML("beforeEnd",violationFields);
		const insertedViolation = violationsControlSet.lastElementChild;
		insertedViolation.querySelector('.person-served').value = personServed;

	}
	requestLocationSearch(){
		const locationAutoComplete = new autoComplete({
			data: {
				src: async () => {
					// Loading placeholder text
					document.querySelector("#codes_inspection_location_id").setAttribute("placeholder", "Loading...");
					// Fetch External Data Source
					const source = await fetch("/search/query.json?type=location&search=");
					const data = await source.json();
					// Returns Fetched data
					return data;
				},
				key: ["Location"]
			},
			selector: "#codes_inspection_location_id"
		});
	}

	setViolationNames(event){
		const personServed = event.currentTarget.value;
		document.querySelector('.person-served').value = personServed;
	}

}
