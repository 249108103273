import {Controller} from "stimulus"

export default class extends Controller {
    static values = {url: String}

    connect() {
        // console.log("pii-datum loaded for " + this.data.get('urlValue'));

    }

    displayData(event) {
        fetch("/pii_data/" + this.data.get('idValue'))
            .then(response => {
                return response.text();
            })
            .then(html => {
                replacePiiArea(html, event.target.closest(".pii-segment"))

            })

    }

    hideData(event) {
        fetch("/pii_data/" + this.data.get('idValue') + '/hide')
            .then(response => {
                return response.text();
            })
            .then(html => {
                replacePiiArea(html, event.target.closest(".pii-segment"))
            })

    }

    editData(event) {
        console.log("editing pii-datum for type " + this.data.get('urlValue'));
        fetch("/pii_data/" + this.data.get('idValue') + '/edit')
            .then(response => {
                return response.text();
            })
            .then(html => {
                console.log(html)
                replacePiiArea(html, event.target.closest(".pii-segment"))
            })

    }

    updateDataSuccess(event) {
        let [data, status, xhr] = event.detail;
        replacePiiArea(xhr.response, event.target.closest(".pii-segment"))
    }

    refreshPiiArea(event) {
        let [data, status, xhr] = event.detail;
        const newBlock = new DOMParser().parseFromString(xhr.response, 'text/html')
        event.target.closest(".pii-container").replaceChild(newBlock.querySelector(".popup-parent"), event.target.closest(".popup-parent"))
    }

    deletePii(event) {
        event.target.closest(".pii-segment").remove();
    }

}

function replacePiiArea(html, oldBlock) {
    const newBlock = new DOMParser().parseFromString(html, 'text/html')
    oldBlock.parentNode.replaceChild(newBlock.querySelector(".pii-segment"), oldBlock)
}

