import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        // console.log("popupFormBehavior");

    }

    closeForm(event) {
        console.log("Closed?");
        event.target.closest(".popup-parent").querySelector(".popup-form").classList.remove("editing");
    }

    openForm(event) {
        event.preventDefault();
        console.log("Opened?");
        event.target.closest(".popup-parent").querySelector(".popup-form").classList.add("editing");
    }


}

function labelDisplayerEvent(inputEvent) {
}