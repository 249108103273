import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from inspections controller");
        // if .schedule-area exists, then open the schedule for the current inspector
        if (document.querySelector('.schedule-area')) {
            const currentInspectorId = getCurrentInspector();
            toggleSchedule(currentInspectorId);
        }

    }

    refreshSchedule(e) {
        let scheduleDateElement = document.getElementById('inspection_inspection_visits_attributes_0_scheduled_for') || document.getElementById('inspection_visit_scheduled_for');
        let scheduleDate = scheduleDateElement ? scheduleDateElement.value : '';
        console.log("refreshing schedule for " + scheduleDate);
        fetch("/inspection_schedules?for_date=" + encodeURIComponent(scheduleDate))
            .then(response => response.text())
            .then(html => {
                    document.querySelector('.schedule-area').innerHTML = html;
                    const currentInspectorId = getCurrentInspector();
                    toggleSchedule(currentInspectorId);
                }
            );

    }

    toggleSchedule(e) {
        e.preventDefault();
        const currentInspectorId = e.target.dataset.inspector;
        toggleSchedule(currentInspectorId);

    }

    displayInspectorSchedule(e) {
        const currentInspectorId = e.target.value;
        var schedules = document.querySelectorAll(".inspector-schedule")
        schedules.forEach(function (schedule) {
            schedule.hidden = true
        })
        toggleSchedule(currentInspectorId)
    }

    inspectionSent(e) {
        console.log("inspection sent")
        // close the modal and add a message to #flash-area that the inspection has been sent
        e.target.closest('.popup-form').classList.remove('editing');
        document.getElementById('flash-area').innerHTML = "<div class='rounded-md bg-green-50 p-4 m-2 border-2 border-green-800'>Inspection has been sent</div>";
    }

}

function toggleSchedule(inspectorId) {
    let inspectorElement = document.getElementById('Inspector-' + inspectorId);
    if (inspectorElement) {
        inspectorElement.hidden = !inspectorElement.hidden;
    }
}

function getCurrentInspector() {
    return document.getElementById('inspection_assignee_id').value
}