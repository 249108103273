import {Controller} from "stimulus";

export default class extends Controller {

    initialize() {
        this.terminal = StripeTerminal.create({
            onFetchConnectionToken: this.fetchConnectionToken,
            onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
        })
    }

    fetchConnectionToken() {
        return fetch('http://localhost:3000/stripe_terminals/connection_token')
            .then(response => response.json())
            .then(data => data.secret);
    }

    charge() {
        //this.clientSecret = fetch('http://localhost:3000/stripe_terminals/payment_intent_token?amount=100')
          //  .then(response => response.json())
           // .then(data => data.client_secret);
        let that = this;
        this.terminal.collectPaymentMethod(this.data.get("clientSecret")).then(function(result) {
            if (result.error) {
                // Placeholder for handling result.error
                console.log("error 1")
            } else {
                that.terminal.processPayment(result.paymentIntent).then(function(result) {
                    if (result.error) {
                        // Placeholder for handling result.error
                    } else if (result.paymentIntent) {
                        // Placeholder for notifying your backend to capture result.paymentIntent.id
                        alert('capture result.paymentIntent.id' + result.paymentIntent.id)
                    }
                });
            }
        });

    }

    discover_readers() {
        const config = {simulated: false};
        let that = this;
        this.terminal.discoverReaders(config).then(function (discoverResult) {
            if (discoverResult.error) {
                console.log('Failed to discover: ', discoverResult.error);
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.log('No available readers.');
            } else {
                let selectedReader = discoverResult.discoveredReaders[0];
                that.terminal.connectReader(selectedReader).then(function (connectResult) {
                    if (connectResult.error) {
                        console.log('Failed to connect: ', connectResult.error);
                    } else {
                        console.log('Connected to reader: ', connectResult.reader.label);
                    }
                });
            }
        });
    }

    connectReader(discoverResult) {
        // fail if in use prevents unnecessary reconnect to SDK
        terminal.connectReader(selectedReader, {fail_if_in_use: false}).then(function (connectResult) {
            if (connectResult.error) {
                console.log('Failed to connect: ', connectResult.error);
            } else {
                console.log('Connected to reader: ', connectResult.reader.label);
            }
        });
    }

    unexpectedDisconnect() {
        console.log("Disconnected from reader")
    }

    createPaymentIntent() {
        var response = fetch('/stripe_payment_intents/create_intent').then(function (response) {
            return response.json();
        }).then(function (responseJson) {
            var clientSecret = responseJson.client_secret;
            // Call stripe.confirmCardPayment() with the client secret.
        });
    }
}
