import {Controller} from "stimulus"


export default class extends Controller {
    static targets = ["template"];

    connect() {
        console.log('loading utilitiesBackflowPenaltyApplier controller')
    }

    applyPenaltiesToSelected(e) {
        const fine = document.getElementById('penalty_amount').value;
        const testerId = document.getElementById('utilities_device_certification_backflow_tester_id').value;
        console.log("applying Backflow Penalties of $" + fine + " for tester id " + testerId);
        const selected = selectedCardIds(document.querySelector('.uc-search-results'));
        console.log(selected);
        document.getElementById('utilities_device_certifications_ids').value = selected;

    }

    penaltyApplied(data) {
        console.log("Penalties applied");
        const updatedEvents = JSON.parse(data.detail[0]);
        Array.from(checkedBoxes(document.querySelector('.uc-search-results'))).map(function (checkElement) {
            const card = checkElement.closest(".mid-result");
            card.classList.add('hidden');
            checkElement.checked = false;
            card.classList.remove('selected')
        })
    }


}

function selectedCardIds(selectionArea) {
    var selectedCardIds = [];
    Array.from(checkedBoxes(selectionArea)).map(function (checkElement) {
        selectedCardIds.push(checkElement.closest(".mid-result").querySelector(".certification-id").value)
        }
    )
    return selectedCardIds
}


function checkedBoxes(selectionArea) {
    return selectionArea.querySelectorAll("input[type='checkbox']:checked")
}