import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from activity-log controller");
    }

    toggleActivity(e) {
        const activityLog = e.target.closest(".activity-log-instance").querySelector(".activity-log-details");
        activityLog.classList.toggle("hidden");
    }


}
