import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from input-field controller");

    }


    toggleChoices(e) {
        console.log("toggling choice")
        if (e.target.value === 'select') {
            document.querySelector(".choices-area").classList.remove("hidden")
        } else {
            document.querySelector(".choices-area").classList.add("hidden")
        }
    }

    addToObjekt(e) {
        console.log("adding input to object")
        const objektId = document.querySelector(".objekt-id").value
        const objektKlass = document.querySelector(".objekt-klass").value
        const inputId = e.target.value
        fetch('/input_fields/assign_to_object', {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                    "input_id": inputId,
                    "objekt_id": objektId,
                    "objekt_klass": objektKlass
                }
            )
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (html) {
            })
    }

}
