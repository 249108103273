import {Controller} from "stimulus"

export default class extends Controller {

    connect() {

    }

    insertFields(e) {
        const fieldType = e.target.value;
        const fieldArea = e.target.closest(".contact-method-pane").querySelector(".contact-fieldset");
        const sequenceSet = e.target.closest(".contact-method-pane").querySelectorAll("#sequence");
        const sequence = sequenceSet.length > 0 ? sequenceSet[sequenceSet.length - 1].value : null;
        const role = e.target.closest(".contact-method-fieldset").querySelector(".role-select");
        const submittable = e.target.closest(".contact-method-fieldset").querySelector(".contact-submittable");
        fieldArea.innerHTML = "";
        if (fieldType != "") {
            fetch('/contact_methods/get_fields?type=' + fieldType)
                .then(response => response.text())
                .then(html => {
                    // let renderedHTML;
                    // if (sequence != null && sequence.length > 0) {
                    //     renderedHTML = html.replace(/0/g, sequence);
                    // } else {
                    //     renderedHTML = html
                    // }
                    // Parse the HTML string into a DOM object
                    let parser = new DOMParser();
                    let importedDom = parser.parseFromString(html, 'text/html');

                    // Iterate over all elements and update the sequence
                    importedDom.querySelectorAll('*').forEach(el => {
                        // Iterate over all attributes of the element
                        const elLabels = importedDom.querySelectorAll("label[for='" + el.id + "']");
                        if (el.name && el.name.includes('0')) {
                            el.name = el.name.replace(/0/g, sequence);
                        }
                        if (el.id && el.id.includes('0')) {
                            el.id = el.id.replace(/0/g, sequence);
                        }
                        if (elLabels && elLabels.length > 0 && elLabels[0].htmlFor && elLabels[0].htmlFor.includes('0')) {
                            elLabels[0].setAttribute('for', el.id);
                        }
                    });

                    // Serialize the DOM object back into a string
                    let serializer = new XMLSerializer();
                    let renderedHTML = serializer.serializeToString(importedDom);
                    fieldArea.insertAdjacentHTML("beforeEnd", renderedHTML);
                    const hiddenField = fieldArea.querySelector("input[type='hidden']");
                    role.classList.add("required-field");
                    submittable.value = "false";
                    if (typeof (hiddenField) != "undefined" && hiddenField != null && parseInt(hiddenField.value) > 1) {
                        hiddenField.value = '0';
                    }
                })
                .catch(error => {
                        console.error('There has been a problem with your fetch operation: ', error);
                    }
                );

        }

    }

    validateForm(e) {
        let emptyFields = []
        const methodSelectReady = Array.from(e.target.closest(".contact-method-fieldset").querySelectorAll(".required-field")).map(field => {
            if (field.value.length > 0) {
                return true
            } else {
                emptyFields.push(field.labels[0].innerText + " Needs Filled In")
                return false
            }
        })
        e.target.closest(".contact-method-fieldset").querySelector(".contact-submittable").value = methodSelectReady.every(Boolean)
        e.target.closest(".contact-method-fieldset").querySelector(".validation-messages").innerHTML = emptyFields.map(msg => {
            return "<p class='notice feedback-text'>" + msg + "</p>"
        }).join(' ')
    }

}
