import {Controller} from "stimulus"
import consumer from '../channels/consumer.js';

var delayTimer;
export default class extends Controller {
    static targets = ['input', 'messages'];

    connect() {
        const scenarioId = document.getElementById('scenarios').value;
        console.log("log from fc-forecasts-status controller");
        this.channel = consumer.subscriptions.create({
            channel: 'FcForecastStatusChannel',
            fc_scenario: scenarioId
        }, {
            connected: this._cableConnected.bind(this),
            disconnected: this._cableDisconnected.bind(this),
            received: this._cableReceived.bind(this),
        });
    }

    clearInput() {
        this.inputTarget.value = '';
    }

    _cableConnected() {
        // Called when the subscription is ready for use on the server
    }

    _cableDisconnected() {
        // Called when the subscription has been terminated by the server
    }

    _cableReceived(data) {
        // Called when there's incoming data on the websocket for this channel
        const oldMessage = this.messagesTarget.innerHTML;
        this.messagesTarget.innerHTML = data.message;
        if (data.message == 'Processing Complete') {
            this.messagesTarget.classList.remove('m-fadeIn');
            this.messagesTarget.classList.add('m-fadeOut');
        } else if (oldMessage == 'Processing Complete' && data.message != 'Processing Complete') {
            this.messagesTarget.classList.add('m-fadeIn');
            this.messagesTarget.classList.remove('m-fadeOut');
        }
    }
}
