import { Controller } from "stimulus"

export default class extends Controller {
    connect(){
        console.log("connect from FormController")
        const fieldSets = document.querySelectorAll("input")
        fieldSets.forEach(function(fieldSet) {
            labelDisplayer(fieldSet.parentElement)
            fieldSet.addEventListener("change", labelDisplayerEvent)
            }
        )

    }



}

function     labelDisplayerEvent(inputEvent) {
    const inputElement = inputEvent.target.parentElement
    labelDisplayer(inputElement)

}

function labelDisplayer(inputElement){
    if (inputElement.querySelector("input") != null) {
        if (inputElement.querySelector("input").value.length > 0) {
            inputElement.querySelector("label").setAttribute("style", "display:block;")
        } else {
            inputElement.querySelector("label").setAttribute("style", "display:none;")
        }

    }
}