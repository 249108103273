import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["links", "template"]

    connect() {
        console.log("log from nested_form controller");
    }

    addAssociation(e) {
        e.preventDefault();
        let content = this.templateTarget.innerHTML;
        content = content.replace(/NEW_RECORD/g, new Date().getTime());
        this.linksTarget.insertAdjacentHTML('beforebegin', content);
    }

    removeAssociation(e) {
        e.preventDefault();
        let wrapper = e.target.closest(".nested-fields");
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove();
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1;
            wrapper.style.display = 'none';
        }
    }

}