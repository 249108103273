import {Controller} from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

    connect() {
        console.log("log from Licensing NAICS Conversion controller");
    }

    getTask(e) {
        console.log("new task selected")
        const naicsTask = document.getElementById("naics_task")
        window.location.href = 'conversion_tasks?current_task=' + naicsTask.value;
    }

    setRenewalClassification(e) {
        const form = e.target.closest('form');
        Rails.fire(form, 'submit');
    }

}
