import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
    }

    removeIdentifier(event) {
        event.target.parentElement.previousElementSibling.remove();
        event.target.parentElement.remove();
    }

}
