import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from nav controller");
    }

    toggleMenu(e) {
        e.target.closest(".module-nav").querySelector(".module-menu").classList.toggle('hidden');
    }

    hideMenu(e) {
        console.log("hide menu");

    }

}