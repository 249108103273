import {Controller} from "stimulus"
import {validateFields, addressFormSubmittable} from "../../../lib/assets/form-validation";

// handles saving
var delayTimer;
export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from address_form controller");

    }

    showAddressForm(e) {
        const addressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        const searchResults = e.target.closest(".address-formset").parentNode.querySelector(".business-location-search-results");
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        if (addressIdField.value.length > 1) {
            fetch('/addresses/add_fields')
                .then(response => response.text())
                .then(html => {
                        addressForm.innerHTML = html;
                    }
                );
        }
        addressForm.classList.remove("hidden");
        searchResults.classList.add("hidden")
        addressIdField.value = "";
    }

    toggleAddressForm(e) {
        const addressForm = e.target.closest(".address-formset").querySelector(".new-address-area");
        const addressOne = addressForm.querySelector(".address-street-one");
        const addressCity = addressForm.querySelector(".address-city");
        const addressState = addressForm.querySelector(".address-state");
        const addressZip = addressForm.querySelector(".address-zip");
        addressForm.classList.toggle("hidden");
        addressOne.classList.toggle("required-field")
        addressCity.classList.toggle("required-field")
        addressState.classList.toggle("required-field")
        addressZip.classList.toggle("required-field")
    }

    toggleSearchResults(e) {
        delayTimer = setTimeout(function () {
            const searchResults = e.target.closest(".search-focus-area").querySelector(".address-search-listing");
            searchResults.classList.toggle("hidden");
        }, 300)
    }

    saveAddress(e) {
        e.preventDefault();
        const addressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        const addressIdKlass = e.target.closest(".address-formset").parentNode.querySelector(".address-klass")
        const addressId = addressIdField.value;
        const addressFormFields = addressForm.querySelectorAll("input");
        const chosenAddressArea = e.target.closest(".address-formset").querySelector(".assigned-address-area");
        let addressData = {};
        addressFormFields.forEach(field => {
            addressData[getLastValueInParentheses(field.name)] = field.value;
        });
        addressData["id"] = addressId;
        fetch('/addresses/', {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(addressData)
        }).then(response => response.json())
            .then((data) => {
                console.log(data);
                addressForm.classList.add("hidden");
                addressIdField.value = data.address.id;
                addressForm.innerHTML = "";
                chosenAddressArea.innerHTML = data.html;
                if (addressIdKlass) {
                    addressIdKlass.value = data.klass;
                    addressIdKlass.dispatchEvent(new Event('change'));
                }
            })
    }

    selectAddress(e) {
        const searchResults = e.target.closest(".address-search-container").querySelector(".business-location-search-results");
        e.target.closest(".address-search-container").querySelector(".add-icon").classList.add("hidden");
        const addressId = e.currentTarget.id;
        const chosenAddress = e.currentTarget;
        const chosenAddressArea = e.target.closest(".address-formset").querySelector(".assigned-address-area");
        const newAddressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        console.log("click on " + addressId);
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        // const parentKlass = 'ContactMethod';
        chosenAddressArea.innerHTML = "";
        chosenAddressArea.appendChild(chosenAddress);
        addressIdField.value = addressId;
        newAddressForm.innerHTML = "";
        searchResults.classList.add('hidden');


    }

    validateForm(e) {
        validateFields(e.target.closest(".address-formset"));
        addressFormSubmittable(e.target.closest(".address-formset"))
    }


}

function getLastValueInParentheses(inputString) {
    const regex = /\[([^\]]+)\]/g;
    let match;
    let lastMatch;
    while ((match = regex.exec(inputString)) !== null) {
        lastMatch = match[1];
    }
    return lastMatch;
}

