import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"
import Rails from "@rails/ujs";

export default class extends Controller {

    connect() {
        console.log("log from bl-license_applications controller");
    }


    updateRenewalAmountDue(event) {
        console.log("I shouldn't be running updateRenewalAmountDue from bl-license-applications-controller")
    }

    updateLicensePeriods(event) {
        console.log("updating license period select")
        const formArea = event.target.closest('.bl-license-application-form')
        const selectArea = formArea.querySelector(".bl-license-application-license-period-area")
        const licenseTypeId = window.bl_license_application_license_type_id.value;
        const applicationId = formArea.querySelector("#bl_license_application_id").value ||
            formArea.querySelector("#itemReferenceId").innerText || 'new';
        if (licenseTypeId.length === 0) {
            console.log("fill in the license Type")
            formArea.querySelector(".feedback-area").innerHTML += '<p class="no-response feedback-text">License Type is missing!</p>';
        } else {
            fetch('/bl_license_applications/render_license_period_selects?license_type_id=' + licenseTypeId + "&business_license_application_id=" + applicationId)
                .then(response => {
                    return response.text();
                })
                .then(html => {
                    if (formArea.querySelector(".no-response") !== null) {
                        formArea.querySelector(".no-response").remove();
                    }
                    selectArea.innerHTML = html;
                });
        }
    }

    saveApplication(event) {
        if (!(window.location.pathname == "/bl_license_estimators/new")) {
            const currentUnitsElement = document.getElementById('bl_license_application_current_units');
            if (currentUnitsElement) {
                const currentUnitsValue = AutoNumeric.getNumber(currentUnitsElement);
                currentUnitsElement.value = currentUnitsValue;
            }

            const unitDeductionElement = document.getElementById('bl_license_application_unit_deduction');
            if (unitDeductionElement) {
                const unitDeductionValue = AutoNumeric.getNumber(unitDeductionElement);
                unitDeductionElement.value = unitDeductionValue;
            }
            const form = event.target.closest('form');
            const formData = new FormData(form);
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            fetch(form.action, {
                method: form.method,
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.id) {
                        const blLicenseApplicationCreatedForm = document.querySelector(".application-form").querySelector('form');
                        const overlay = blLicenseApplicationCreatedForm.closest(".popup-form");
                        blLicenseApplicationCreatedForm.action = data.form_action;
                        blLicenseApplicationCreatedForm.method = "post";
                        blLicenseApplicationCreatedForm.insertAdjacentHTML('afterbegin', '<input type="hidden" name="_method" value="patch">');
                        const blaCartCreatedReference = blLicenseApplicationCreatedForm.querySelector("#itemReferenceId");
                        blaCartCreatedReference.innerText = data.id;
                        if (data.amount_outstanding > 0.0) {
                            const blaCreatedChargeButton = blLicenseApplicationCreatedForm.querySelector(".add-charge-area");
                            blaCreatedChargeButton.classList.remove("hidden");
                        }
                        document.getElementById("bl_license_application_id").value = data.id;
                        document.getElementById("bl_license_application_licensable_id").value = data.licensable_id;
                        document.getElementById("bl_license_application_licensable_type").value = data.licensable_type;
                        if (overlay != null) {
                            overlay.classList.add("editing");
                        }
                    } else {
                        console.error("Error updating the application");
                    }
                })
                .catch(error => console.error('Error:', error));
        }

    }

}
