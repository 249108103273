import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("shared-response");

    }


    displayResponse(event) {
        // const [data, status, xhr] = event.detail;
        // document.getElementById('notice').innerHtml = xhr.response;
        // document.getElementById('notice').focus();
    }


}

