import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from contact-merge controller");
    }

    toggleControl(e) {
        console.log("control toggled")
        e.target.closest(".merge-action").querySelector(".contact-select-area").classList.toggle("hidden")
    }


    selectSubsumed(e) {
        e.preventDefault();
        const subsumed = e.target.closest('li').id;
        const current = e.target.closest('.contact-select-area').querySelector('#contacts_excluded_ids').value;
        const subsumedname = e.target.closest('li').querySelector('.result-primary').innerText
        if (current === subsumed) {
            alert("Existing contact cannot be merged into itself.")
            return
        }
        if (subsumed.length > 0) {
            if (confirm('Merge contact ' + subsumed + ': ' + subsumedname)) {
                console.log("subsumed selected")
                fetch(window.location.href + '/subsume?subsumed=' + subsumed)
                    .then(response => response.text())
                    .then(html => {
                            console.log("subsume successful")
                            document.querySelector('.contact-select-area').classList.add("hidden");
                            document.getElementById('flash-area').innerHTML = "<p class=\"notice\">Contact Successfully Merged</p>";

                        }
                    );
            } else {
                e.target.closest('.contact-select-area').classList.add("hidden");

            }
        }
    }

}
