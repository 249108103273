import {Controller} from "stimulus"
import {contactFormSubmittable} from "../../../lib/assets/form-validation";


export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from contact-select controller");
    }

    selectContact(e) {
        e.preventDefault();
        e.target.closest(".bl-search-results").classList.remove("showing");
        const chosenContactId = e.currentTarget.id;
        const selectPopup = e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup");
        const selectResultsArea = selectPopup.querySelector(".contact-method-selection-area")
        const contactFormArea = document.querySelector(".contact-form-area")
        console.log('contact selected: ' + chosenContactId);
        fetch("/contact_methods/contact_methods_for_contact.js?contact_id=" + encodeURIComponent(chosenContactId))
            .then(response => {
                return response.text();
            })
            .then(html => {
                // hacky as heck; the contact method list gets returned as an <h3> while the contact without methods
                // gets returned as an <h4>.  Need to retool the controllers if possible to return an hash with the indicator
                // or call .js and have the response deliver the javascript to  handle these changes.
                if (html.substring(1, 3) === 'h3') {
                    selectResultsArea.innerHTML = html;
                    selectPopup.classList.add("editing");
                } else {
                    contactFormArea.innerHTML = html;
                    e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup").classList.remove("editing");
                    contactFormArea.classList.remove("hidden");
                    e.target.closest(".contact-search-area").classList.add("hidden");
                    contactFormSubmittable();
                }
            })
    }

    enableNew(e) {
        e.target.closest(".contact-search-area").querySelector(".add-contact-control").classList.remove("hidden");

    }

    showContactForm(e) {
        e.target.closest(".contact-search-area").classList.add("hidden");
        e.target.closest(".contact-relation-fieldset").querySelector(".contact-form-area").classList.remove("hidden");
        e.target.closest(".contact-relation-fieldset").querySelector(".contact-form-area").querySelector(".start-field").focus();
    }

    addContact(e) {
        e.preventDefault();
        const chosenContactId = e.target.dataset.contact;
        const contactMethods = e.target.closest(".contact-select-area").querySelectorAll(".contact-method")
        const contactFormArea = document.querySelector(".contact-form-area")
        let contactMethodIds = []
        Array.from(contactMethods).map(contactMethod => {
            if (contactMethod.checked) {
                contactMethodIds.push(contactMethod.id)
            }
        })
        console.log("clicked on " + contactMethods.length + "methods for contact" + chosenContactId)
        fetch("/contacts/render_contact?contact_id=" + encodeURIComponent(chosenContactId) +
            "&contact_method_ids=" + encodeURIComponent(contactMethodIds))
            .then(response => {
                return response.text();
            })
            .then(html => {
                contactFormArea.innerHTML = html;
                e.target.closest(".contact-select-area").querySelector(".contact-method-select-popup").classList.remove("editing");
                contactFormArea.classList.remove("hidden");
                e.target.closest(".contact-search-area").classList.add("hidden");
                contactFormSubmittable();
            })
    }
}
