import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from classification_search controller");

    }

    showAddressForm(e) {
        const addressForm = e.target.closest(".new-business-location-form").querySelector(".address-form-area");
        const searchResults = document.querySelector(".business-location-search-results");
        addressForm.classList.remove("hidden");
        searchResults.classList.add("hidden")
    }


    requestSearch(e) {
        clearTimeout(delayTimer);
        var component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = component.searchResultTarget;

            if (component.searchItemTarget.value.length > 3) {
                resultsArea.classList.remove("hidden")
                fetch('/search/location_query.html?search=' + encodeURIComponent(component.searchItemTarget.value))
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.querySelector("ul").innerHTML = html;
                        e.target.closest(".business-location-search-container").querySelector(".add-icon").classList.remove("hidden");
                    });
            } else {
                resultsArea.classList.add("hidden")
                e.target.closest(".business-location-search-container").querySelector(".add-icon").classList.remove("hidden");
            }

        }, 800)

    }

    selectAddress(e) {
        const searchResults = e.target.closest(".business-location-search-results");
        const businessLocationId = e.currentTarget.id;
        const chosenBusinessLocation = e.currentTarget;
        const chosenBusinessLocationArea = e.target.closest(".business-location-search-container").querySelector(".business-location-selection").querySelector("ul");
        console.log("click on " + businessLocationId);
        const locationField = document.getElementById("location_id");
        const locationKlassField = window.location_klass;

        chosenBusinessLocationArea.innerHTML = "";
        chosenBusinessLocationArea.appendChild(chosenBusinessLocation);
        chosenBusinessLocation.classList.remove('hidden');
        locationField.value = businessLocationId;
        locationKlassField.value = e.currentTarget.type;
        searchResults.classList.add('hidden');
        const licenseArea = e.target.closest(".business-application-location");
        if (licenseArea) {
            licenseArea.querySelector(".add-icon").classList.add("hidden");
        }

    }


}
