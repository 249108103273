// drawer_controller.js
import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("Drawer controller connected!")
    }

    toggle(e) {
        e.target.closest(".drawer-area").querySelector("#drawer").classList.toggle('translate-x-full');
    }
}