import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        console.log("log from task-builder controller");


    }

    buildTask(e) {
        const taskType = e.target.value;
        const permittingPath = e.target.dataset.taskable;
        window.location.href = permittingPath + '/tasks/new?task_type=' + taskType
    }
}