import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from input-assignment controller");

    }

    updateOrder(e) {
        console.log("changing order");
        const newOrder = e.target.value;
        const inputId = e.target.parentNode.parentNode.querySelector("#input_assignment_id").value
        fetch("update_order.js?new_order=" + newOrder + "&input_assignment_id=" + inputId)
            .then(response => {
                return response.text();
            })
            .then(html => {
                console.log(html)
            })
    }


}
