import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";


export default class extends Controller {

    connect(e) {
        console.log("log from ar-deposits controller");
        this.updateTotal(e);
        this.postButtonState(e);
    }

    importEntries(e) {
        e.preventDefault();
        console.log('importing entries')
        const ard = window.location.pathname.split('/')[2];
        const importing_entries = Array.from(e.target.closest(".import-entries-area").querySelectorAll(".import-entry-checkbox")).map(el => {
            if (el.checked) {
                return el.value
            }
        }).filter(el => (el))
        console.log(importing_entries)
        fetch('/ar_deposits/' + ard + '/import_entries?entries=' + JSON.stringify(importing_entries))
            .then(response => {
                return response.text();
            })
            .then(html => {
                console.log(html)
                document.querySelector("#new-memo-row").insertAdjacentHTML('afterend', html);
                document.querySelector("#import-entries-button").disabled = true;
                document.querySelector(".deposit-import").classList.add('hidden');
                document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">Items Successfully Imported</p>";
                this.updateTotal(e);
                this.postButtonState(e);
            })
    }

    importEntryEnabler(e) {
        console.log('enable import button?')
        const checked_boxes = Array.from(e.target.closest(".import-entries-area").querySelectorAll(".import-entry-checkbox")).map(el => {
            return el.checked
        })
        console.log(checked_boxes)
        if (checked_boxes.some(el => el === true)) {
            document.getElementById('import-entries-button').disabled = false;
        } else {
            document.getElementById('import-entries-button').disabled = true;
        }
    }

    addEntryButtonState(e) {
        console.log('checking ar-deposits form readiness')
        const formArea = e.target.closest(".ar-deposit-form");
        const dateSelectPresent = formArea.querySelector("#ar_deposit_post_date").value.length > 0;
        const depositAmountPresent = AutoNumeric.getNumber(formArea.querySelector("#ar_deposit_total_deposit"));
        const memoSubmitButton = formArea.querySelector(".add-entries-button");
        memoSubmitButton.disabled = !(dateSelectPresent && depositAmountPresent);
    }

    validateGrandTotal(e) {
        console.log('validating grand total');
        const grandTotal = document.getElementById('ar_deposit_total_deposit')
        if ((grandTotalValid()) || subTotalsZero()) {
            grandTotal.classList.remove('input--invalid')
            grandTotal.closest('.input-wrapper').classList.remove('input-wrapper--invalid')
            grandTotal.closest('.input-wrapper').querySelector('.input__error').classList.add('hidden')
        } else {
            grandTotal.classList.add('input--invalid')
            grandTotal.closest('.input-wrapper').classList.add('input-wrapper--invalid')
            grandTotal.closest('.input-wrapper').querySelector('.input__error').classList.remove('hidden')
        }
    }

    validateSubTotals(e) {
        console.log('validating sub total');
        const subtotalFields = document.querySelectorAll(".tender-type-input");
        Array.from(subtotalFields).map(el => {
            if (subTotalValid(el) || makeNumber(el.value) === 0.0) {
                el.classList.remove('input--invalid')
                el.closest('.input-wrapper').classList.remove('input-wrapper--invalid')
                el.closest('.input-wrapper').querySelector('.input__error').classList.add('hidden')
            } else {
                el.classList.add('input--invalid')
                el.closest('.input-wrapper').classList.add('input-wrapper--invalid')
                el.closest('.input-wrapper').querySelector('.input__error').classList.remove('hidden')
            }
        })

    }

    postButtonState(e) {
        document.getElementById('post-deposit-button').disabled = !(grandTotalValid() && allSubTotalsValid())
    }

    updateTotal(e) {
        console.log('updating the totals')
        const entryRows = document.querySelectorAll(".ar-entered-row")
        let entryTotals = {}
        let tenderSelector;
        Array.from(entryRows).map(el => {
            const tenderType = el.querySelector(".entry-type").innerText;
            const tenderAmount = makeNumber(el.querySelector(".ar-entry-amount").innerText)
            entryTotals[tenderType] = entryTotals[tenderType] || 0.0
            entryTotals.grandTotal = entryTotals.grandTotal || 0.0
            entryTotals[tenderType] += tenderAmount
            tenderSelector = "current-total-" + tenderType
            if (!(AutoNumeric.isManagedByAutoNumeric(document.getElementById(tenderSelector)))) {
                new AutoNumeric(document.getElementById(tenderSelector), entryTotals[tenderType])
            } else {
                AutoNumeric.set(document.getElementById(tenderSelector), entryTotals[tenderType])
            }
            entryTotals.grandTotal += tenderAmount
        })
        const subTotalRows = document.querySelectorAll(".tender-type-input")
        let currentGrandTotal = 0.0;
        Array.from(subTotalRows).map(el => {
            currentGrandTotal += makeNumber(el.value);
        })
        if (!(AutoNumeric.isManagedByAutoNumeric(document.getElementById('current-total-grandTotal')))) {
            new AutoNumeric(document.getElementById('current-total-grandTotal'), currentGrandTotal);
        } else {
            AutoNumeric.set(document.getElementById('current-total-grandTotal'), currentGrandTotal)
        }
        this.validateSubTotals(e);
        this.validateGrandTotal(e);
    }


}

function makeNumber(textVal) {
    return Number(textVal.replace("$", "").replace(",", "").trim());
}

function grandTotalValid() {
    const grandTotal = document.getElementById('ar_deposit_total_deposit')
    const subtotalFields = document.querySelectorAll(".tender-type-input");
    let subTotalSum = 0.0;
    Array.from(subtotalFields).map(el => {
        subTotalSum = subTotalSum + makeNumber(el.value);
    })
    return subTotalSum === makeNumber(grandTotal.value);
}

function subTotalsZero() {
    const subtotalFields = document.querySelectorAll(".tender-type-input");
    let subTotalSum = 0.0;
    Array.from(subtotalFields).map(el => {
        subTotalSum = subTotalSum + makeNumber(el.value);
    })
    return subTotalSum === 0.0
}

function allSubTotalsValid() {
    const subtotalFields = document.querySelectorAll(".tender-type-input");
    let allValid = true
    Array.from(subtotalFields).map(el => {
        if (!subTotalValid(el)) {
            allValid = false;
        }
    })
    return allValid
}

function subTotalValid(subtotalField) {
    console.log(subtotalField)
    const subTotalValue = makeNumber(subtotalField.value);
    const entriesSubTotal = makeNumber(subtotalField.closest('.input-wrapper').querySelector(".current-total-area").innerText)
    return subTotalValue === entriesSubTotal
}
