import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from univeral_add controller");

    }

    toggleSubMenu(e) {
        console.log('clicked uniAdd');
        this.element.querySelector(".add-sub-menu").classList.toggle('hidden');
    }

}
