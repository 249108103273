import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("status-flag-modal-controller");

    }


    toggleModal(event) {
        console.log("show modal");
        let modal = document.querySelector('.status-flag-modal');
        modal.classList.toggle('hidden');
    }

    toggleFlag(event) {
        console.log("add flag");
        const flag = event.target.closest(".status-flag")
        console.info(flag)
        if (flag.dataset.id.length > 0) {
            //send request to delete flag at /status_flags/:id
            fetch(`/status_flags/${flag.dataset.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }).then(response => response.json())
                .then(data => {
                        console.log(data);
                        if (data.status === 'success') {
                            flag.remove();
                        } else {
                            console.log('Error deleting flag');
                        }
                    }
                );

        } else {
            //send request to create flag at /status_flags/
            fetch('/status_flags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    status_flag_flag: {
                        status_flag_type_id: flag.dataset.statusFlagTypeId,
                        status_flaggable_id: flag.dataset.statusFlagFlaggableId,
                        status_flaggable_type: flag.dataset.statusFlagFlaggableType
                    }
                })
            }).then(response => response.json())
                .then(data => {
                    console.log(data);
                    if (data.status === 'success') {
                        flag.dataset.id = data.id;
                        //move flag from current spot to last child in .active-flags
                        flag.parentNode.removeChild(flag);
                        document.querySelector('.active-flags').appendChild(flag);
                    } else {
                        console.log('Error saving flag');
                    }
                })
        }
    }


}

