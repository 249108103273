import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from reports InspectionsPareto");
    }

    updateReport(e) {
        console.log("report changed")
        const reportYear = document.getElementById("year")
        const reportMonth = document.getElementById("month")
        const title = "Monthly " + this.data.get('menuLabel') + " Pareto: " + reportMonth.value + "/" + reportYear.value;
        const jurisdiction = document.getElementById("jurisdiction_id");
        let reportUrl = 'chart?title=' + title + '&data_source=' + this.data.get('url') + '&year=' + reportYear.value + '&month=' + reportMonth.value;
        if (jurisdiction != null) {
            reportUrl = reportUrl + '&jurisdiction_id=' + jurisdiction.value
        }
        window.location.href = reportUrl

    }
}