import {Controller} from "stimulus"

let draggable
export default class extends Controller {

    connect() {
        console.log("hello from inspectionPlanner");
    }

    updateReport(e) {
        console.log("report changed");
        const reportDate = document.getElementById("for_date")
        window.location.href = '?for_date=' + reportDate.value;
    }

    dragInspection(e) {
        console.log("dragging inspection");
        e.target.classList.add("opacity-30")
        draggable = e.target
    }

    touchInspection(e) {
        console.log("touching inspection");
        e.preventDefault();
        draggable = e.target.closest(".inspection-visit")
    }

    dragStopInspection(e) {
        console.log("done dragging inspection");
        e.target.classList.remove("opacity-30")
    }

    onAssignable(e) {
        e.preventDefault();
        console.log('over an assignable inspector');
        return true;
    }

    visitAssignable(e) {
        console.log("can assign to inspector")
        e.target.classList.add("animate-pulse")
        setTimeout(function () {
            e.target.classList.remove("animate-pulse");
        }, 3000);
        return true;
    }

    leaveAssignable(e) {
        console.log("left assignable inspector")
        e.target.classList.remove("animate-pulse")

    }


    assignTouchedInspection(e) {
        e.preventDefault();
        console.log("assigning to inspector")
        const touch = e.changedTouches[0];
        const lastTouchedElement = document.elementFromPoint(touch.clientX, touch.clientY);
        if (lastTouchedElement.closest(".inspector-column")) {
            lastTouchedElement.closest(".inspector-column").querySelector(".inspector-schedule").prepend(draggable);
            const inspectorid = lastTouchedElement.closest(".inspector-column").dataset.inspectorId;
            const inspectionId = draggable.dataset.inspectionId;
            const inspectionVisitId = draggable.dataset.inspectionVisitId;
            updateInspectionVisit(inspectorid, inspectionId, inspectionVisitId);
            draggable.classList.remove("opacity-30");
            draggable.classList.add("animate-pulse");
            setTimeout(function () {
                draggable.classList.remove("animate-pulse");
            }, 1000);
        }

    }

    assignInspection(e) {
        e.preventDefault();
        console.log("assigning to inspector")
        const inspectorid = e.target.closest(".inspector-column").dataset.inspectorId;
        const inspectionId = draggable.dataset.inspectionId;
        const inspectionVisitId = draggable.dataset.inspectionVisitId;
        updateInspectionVisit(inspectorid, inspectionId, inspectionVisitId);
        e.target.closest(".inspector-column").querySelector(".inspector-schedule").prepend(draggable);
        draggable.classList.remove("opacity-30");
        draggable.classList.add("animate-pulse");
        setTimeout(function () {
            draggable.classList.remove("animate-pulse");
        }, 1000);
    }
}

function updateInspectionVisit(inspectorid, inspectionId, inspectionVisitId) {
    console.log("updating inspection visit");
    fetch('/inspections/' + inspectionId + '/inspection_visits/' + inspectionVisitId, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({inspection_visit: {performed_by_id: inspectorid}})
    }).then(response => response.json())
        .then(data => {
            // console.log(data);
            document.querySelectorAll(".inspector-column").forEach((inspector) => {
                //get count of .inspection-visit
                inspector.querySelector(".inspector-count").innerHTML =
                    inspector.querySelectorAll(".inspection-visit").length;
            });
        });
}

