import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {
    static targets = ["newMemoRow", "balanceLine", "requestRefund", "memoEntryDescription", "memoEntryAmount"];

    connect() {
        console.log("log from memo_entries controller");
    }

    postButtonState(e) {
        const formArea = e.target.closest('.memo-entry-form');
        const dateSelectPresent = formArea.querySelector("#memo_entry_posted_on").value.length > 0;
        const memoDescriptionPresent = formArea.querySelector("#memo_entry_description").value.length > 0;
        const memoAmountPresent = AutoNumeric.getNumber(formArea.querySelector("#memo_entry_amount"));
        const memoSubmitButton = formArea.querySelector(".button");
        memoSubmitButton.disabled = !(dateSelectPresent && memoDescriptionPresent && memoAmountPresent);
    }

    handleCreate(event) {
        event.preventDefault();
        // AutoNumeric.unformat(document.querySelector("#memo_entry_amount"))
        let component = this;
        const form = event.target.form;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.json();
            })
            .then(data => {

                if (data.entry) {
                    document.querySelector("#new-memo-row").insertAdjacentHTML('afterend', data.entry_html);
                    document.querySelector("#balance-line").innerText =
                        `Current Balance ${AutoNumeric.format(data.account_total, {
                            currencySymbol: '$'
                        })}`;
                    if (data.account_total < 0.0) {
                        document.querySelector("#request-refund").classList.remove('hide-refund');
                    } else {
                        document.querySelector("#request-refund").classList.add('hide-refund');
                    }
                    component.memoEntryDescriptionTarget.value = "";
                    AutoNumeric.set(component.memoEntryAmountTarget, 0);
                    if (document.querySelector("#bl_check_request_amount")) {
                        document.querySelector("#bl_check_request_amount").value = data.account_total * -1;
                    }
                } else {
                    console.error("Error updating the form");
                }
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set card payment. Please try again.');
            });


    }

}