export function validateFields(area) {
    let emptyFields = []
    const htmlArea = area
    const requiredFields = htmlArea.querySelectorAll(".required-field")
    const validationFlagField = htmlArea.querySelector("." + area.classList[0] + "-submittable")
    if (!(requiredFields == null || requiredFields.length === 0)) {
        const methodSelectReady = Array.from(requiredFields).filter(function (element) {
            return element.nodeName != "DIV";
        })
            .map(field => {
                // bypass slimselect divs that replicate the class of the original select
                if (!field.classList.contains("ss-main")) {
                    if (field.value.length > 0) {
                        return true
                    } else {
                        emptyFields.push(field.labels[0].innerText + " Needs Filled In")
                        return false
                    }
                }

            })
        validationFlagField.value = methodSelectReady.every(Boolean)
        document.getElementById("validation-messages").innerHTML = emptyFields.map(msg => {
            return "<p class='notice feedback-text field-validation-message'>" + msg + "</p>"
        }).join(' ')
    } else {
        //remove previous validation messages
        document.getElementById("validation-messages").querySelectorAll(".field-validation-message").forEach(msg => {
            msg.remove()
        })
        if (validationFlagField) {
            validationFlagField.value = true
        }
    }
}

export function contactFormSubmittable() {
    let contactTypeSelected = true;
    const contactMethodsValid = Array.from(document.querySelectorAll(".contact-submittable")).map(field => {
        return field.value === 'true'
    }).every(Boolean)
    console.log("contactMethodsValid: " + contactMethodsValid)
    if (!((document.body.contains(document.getElementById('contact_relation_contact_type_id'))) && (document.getElementById('contact_relation_contact_type_id').value))) {
        contactTypeSelected = false
    }
    if (document.querySelectorAll('.contact-save-button').length > 0) {
        document.querySelector('.contact-save-button').disabled = !(contactMethodsValid && contactTypeSelected)
    }
}

export function formSubmittable(area) {
    const htmlArea = area
    const submittable = htmlArea.querySelector("." + area.classList[0] + "-submittable").value;
    console.log("submittable: " + submittable)
    Array.from(htmlArea.querySelectorAll(".validatable-button")).map(button => {
        button.disabled = !eval(submittable)
    })
}

export function addressFormSubmittable(addressForm) {
    let contactTypeSelected = true;
    const contactMethodsValid = addressForm.querySelector(".address-formset-submittable").value === 'true'
    console.log("addressFormValid: " + contactMethodsValid)
    if (document.body.contains(document.getElementById('contact_relation_contact_type_id'))) {
        contactTypeSelected = document.getElementById('contact_relation_contact_type_id').valueOf.length == 0
    }
    if (addressForm.querySelectorAll('.save-address-button').length > 0) {
        addressForm.querySelector('.save-address-button').disabled = !(contactMethodsValid && contactTypeSelected)
    }
}
