import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from parcel-select controller");

    }

    requestParcelSearch(e) {
        clearTimeout(delayTimer);
        let component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = component.searchResultTarget;
            resultsArea.classList.remove("hidden")
            if (component.searchItemTarget.value.length > 2) {
                fetch('/search/parcel_query.html?search='
                    + encodeURIComponent(component.searchItemTarget.value) +
                    "&limit=" + encodeURIComponent((e.target.dataset.limit || 5)),
                )
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        if (html.length > 0) {
                            resultsArea.querySelector(".searching-interim").classList.add("hidden");
                            resultsArea.querySelector(".results-list").innerHTML = html;
                            resultsArea.querySelector(".query-gis").classList.remove("hidden");
                        } else {
                            resultsArea.querySelector(".searching-interim").classList.add("hidden");
                            resultsArea.querySelector(".query-gis").classList.remove("hidden");
                        }

                    });
            } else {
                resultsArea.classList.add("hidden")
            }
        }, 800)

    }

    queryGisServer(e) {
        e.preventDefault();
        let component = this;
        const resultsArea = component.searchResultTarget;
        resultsArea.querySelector(".searching-interim").classList.remove("hidden");
        fetch('/search/parcel_gis_query.html?search=' + encodeURIComponent(component.searchItemTarget.value))
            .then(response => {
                return response.text();
            })
            .then(html => {
                if (html.length > 0) {
                    resultsArea.querySelector(".results-list").innerHTML = html;
                    resultsArea.querySelector(".searching-interim").classList.add("hidden");
                    resultsArea.querySelector(".query-gis").classList.add("hidden");
                } else {
                    resultsArea.querySelector(".searching-interim").classList.add("hidden");
                    resultsArea.querySelector(".no-results").classList.remove("hidden");
                }

            });
    }

    shellParcel(e) {
        e.preventDefault();
        let component = this;
        const resultsArea = component.searchResultTarget;
        resultsArea.querySelector(".searching-interim").classList.remove("hidden");
        fetch('/parcels/shell_builder?identifier=' + encodeURIComponent(component.searchItemTarget.value))
            .then(response => {
                return response.text();
            })
            .then(html => {
                if (html.length > 0) {
                    resultsArea.querySelector(".results-list").innerHTML = html;
                    resultsArea.querySelector(".searching-interim").classList.add("hidden");
                    resultsArea.querySelector(".query-gis").classList.add("hidden");
                }
            });
    }

    selectParcel(e) {
        const parcelSelectedArea = e.target.closest(".parcel-select-container").querySelector(".selected-parcels").querySelector("ul");
        const chosenParcel = e.currentTarget;
        if (!chosenParcel.classList.contains("selected")) {
            const chosenParcelId = chosenParcel.id;
            const resultsArea = this.searchResultTarget;
            const chosenTemplate = parcelSelectedArea.querySelector(".assignment-template");
            const newListItem = document.createElement("li");
            const newAssignment = chosenTemplate.cloneNode(true);
            const timeStamp = Date.now();
            const parcelSelectArea = e.target.closest(".popup-form");
            parcelSelectedArea.appendChild(chosenParcel);
            chosenParcel.classList.add("moved");
            newAssignment.id = newAssignment.id.replace(/_\d{1,2}_/, timeStamp);
            newAssignment.name = newAssignment.name.replace(/\[\d{1,2}\]/, timeStamp);
            newAssignment.value = chosenParcelId;
            newListItem.classList.add("hidden");
            newListItem.appendChild(newAssignment);
            parcelSelectedArea.appendChild(newListItem);
            resultsArea.classList.add("hidden");
            var event = new Event('click');
            parcelSelectedArea.dispatchEvent(event);
            if (parcelSelectArea !== null) {
                parcelSelectArea.classList.remove("editing");
            }
        }
    }

    deleteParcel(e) {
        const parcelToRemove = e.target.closest(".result-parcel");
        const chosenParcelId = parcelToRemove.id;
        const resultsArea = this.searchResultTarget;
        parcelSelectedArea.removeChild(chosenParcel);
        Array.from(document.querySelector(".parcel-select-container")
            .querySelectorAll("input"))
            .map(function (input) {
                if (input.value === chosenParcelId) {
                    input.remove();
                }
            })
    }


}
