import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        console.log("codesViolationFields Controller");
    }

    fillDefaultInspection(event){
        console.log("codesViolationFields#fillDefaulInspection clicked");
        if (event.target.value == ''){
            event.target.value = event.target.closest(".codes-violation-fields").querySelector(".current-user").value;
        }
    }
}