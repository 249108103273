import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static targets = ["internalResults", "queryData"];

    connect() {
        console.log("log from address_search_v2 controller");

    }


    requestSearch(e) {
        clearTimeout(delayTimer);
        var component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = component.internalResultsTarget;
            const query = component.targets.findAll('queryData').map(el => {
                return el.value
            }).filter(Boolean).join(",")
            if (query.length > 2) {
                resultsArea.classList.remove("hidden")
                fetch('/search/address_query.html?search=' + encodeURIComponent(query) + "&type=internal_results")
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.innerHTML = html;
                    });
            } else {
                resultsArea.classList.add("hidden")
            }

        }, 800)

    }

    selectAddress(e) {
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        const addressId = e.currentTarget.id;
        const chosenAddress = e.currentTarget;
        const chosenAddressArea = e.target.closest(".address-formset").querySelector(".assigned-address-area");
        const addressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        console.log("click on " + addressId);
        addressForm.classList.add("hidden");
        addressForm.querySelectorAll("input").forEach(field => {
            field.classList.remove('required-field')
        })
        addressIdField.value = addressId;
        chosenAddressArea.innerHTML = chosenAddress.innerHTML;
    }


}
