import { Controller } from "stimulus"
// TODO: use this for navigating the search results
export default class extends Controller {
  static targets = [ "searchResults", "searchItem" ]

  connect() {
    console.log("log from utilityDeviceSearchResultsNavigation controller");


  }

  requestSearch(){
        const resultsArea = document.querySelector('.uc-search-results')
        if (this.searchItemTarget.value.length > 2){
            resultsArea.classList.add("showing")
            fetch('/utilities_device_certifications/query.html?search=' + encodeURIComponent(this.searchItemTarget.value))
                .then(response=> {
                    return response.text();
                })
                .then(html=> {
                    resultsArea.innerHTML=html;
                });
        }else{
            resultsArea.classList.remove("showing")
        }

  }

}
