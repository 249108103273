import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from file-library controller");
    }

    deleteFile(e) {
        e.preventDefault();
        e.target.closest("li").remove();
    }


}