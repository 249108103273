import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from permitting-search controller");
        Array.from(document.querySelector('.selected-parcels').querySelectorAll('.result-location'))
            .map(parcel => {
                parcel.classList.add('result-parcel');
            });
        Array.from(document.querySelector('.selected-parcels').querySelectorAll('.delete-action'))
            .map(parcel => parcel.classList.remove('hidden'));

    }

    getDelayedReport(e) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            reloadReport();
        }, 2100)

    }

    removeParcel(e) {
        e.preventDefault()
        const parcel = e.target.closest('.result-parcel');
        const selectedParcels = document.querySelector('.selected-parcels').querySelector('.results-list');
        selectedParcels.removeChild(parcel);
        reloadReport()
    }

    getReport(e) {
        reloadReport();
    }
}

function reloadReport() {
    // const postingDate = document.getElementById('report_date').value;
    const active = document.getElementById('active').checked;
    // const ccOnly = document.getElementById('cc_only').checked;
    // const activitySourceControls = document.querySelectorAll('.activity-source')
    const selectedParcelDoms = document.querySelector('.selected-parcels').querySelectorAll('.result-parcel');
    const selectedParcels = Array.from(selectedParcelDoms).map(parcel => parcel.id);
    const selectedTemplates = Array.from(document.getElementById('planning_case_template_id')
        .querySelectorAll(':checked')).map(option => option.value)
    const jurisdiction = document.getElementById("jurisdiction").value
    // const projectName = document.getElementById("name").value
    const appliedSince = document.getElementById("frame_start").value
    const appliedUntil = document.getElementById("frame_end").value
    console.log("finding permits for report ");
    window.location.href = "/planning/cases?active=" + active +
        "&jurisdiction=" + jurisdiction +
        "&parcels=" + encodeURIComponent(selectedParcels) +
        "&app_template_ids=" + encodeURIComponent(selectedTemplates) +
        "&frame_start=" + encodeURIComponent(appliedSince) +
        "&frame_end=" + encodeURIComponent(appliedUntil);
}
