import {Controller} from "stimulus"
import Rails from "@rails/ujs";
// import {configure} from "honeybadger-js";

// var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from bl-business controller");

    }

    updateBusiness(e) {
        const businessForm = this.businessFormTarget;
        Rails.fire(businessForm, 'submit');
    }


    showLocationChangeControl(e) {
        e.preventDefault;

    }

    enableMove(e) {
        console.log("move address selected");
        if (e.target.closest('.move-business-control') !== null) {
            const moveDate = e.target.closest('.move-business-control').querySelector("#business_move_date");
            const locationID = e.target.closest('.move-business-control').querySelector("#bl_license_application_location_id");
            const locationKlass = e.target.closest('.move-business-control').querySelector("#bl_license_application_location_klass");
            if (e.currentTarget.dataset.type == 'Parcel' || e.currentTarget.dataset.type == 'Address') {
                locationID.value = e.currentTarget.id;
                locationKlass.value = e.currentTarget.dataset.type;

            }
            const moveButton = e.target.closest('.move-business-control').querySelector(".move-location-button");
            const locationSearchForm = e.target.closest('.move-business-control').querySelector(".business-location-search-form");
            const locationAddButton = e.target.closest('.move-business-control').querySelector(".new-business-location-form");
            if ((moveDate.value.length > 1) && (locationID.value.length > 1) && (locationKlass.value.length > 1)) {
                moveButton.classList.remove('hidden');
                locationSearchForm.classList.add('hidden');
                locationAddButton.classList.add('hidden');

            }
        } else {
            console.log("no move control present; assuming new location")
            const businessId = document.getElementById('business_id').value
            const businessLocationPopup = e.target.closest('.popup-form');
            let data = {
                business_location_params: {
                    addressableId: e.currentTarget.id,
                    addressableKlass: e.currentTarget.dataset.type,
                    businessId: businessId
                }
            };
            fetch('/business_locations/create', {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(response => response.json())
                .then((data) => {
                    console.log(data);
                    document.querySelector(".bl-locations-container")
                        .querySelector(".business-location-info-box")
                        .insertAdjacentHTML('beforebegin', data.html);
                    businessLocationPopup.classList.remove('editing');
                    document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">Location Successfully Added</p>";
                })
                .catch((error) => console.log(error)
                )

        }
    }

    allowAddressSave(e) {
        console.log("bl-business controller: allowAddressSave this should not run");
        // const addressForm = e.target.closest(".address-form");
        // const saveButton = addressForm.querySelector(".save-address-button");
        //
        // if (addressForm.querySelector(".address-street-one").value.length > 1 &&
        //     addressForm.querySelector(".address-city").value.length > 1 &&
        //     addressForm.querySelector(".address-state").value.length > 1 &&
        //     addressForm.querySelector(".address-zip").value.length > 3) {
        //     saveButton.disabled = false;
        // } else {
        //     saveButton.disabled = true;
        // }
    }

    saveAddress(e) {
        console.log("Address Saved!");
        const locationSaveForm = e.target.closest('.move-business-control').querySelector(".location-move-form").closest('.move-business-control');
        const addressForm = locationSaveForm.querySelector(".address-form");
        const addressLineOne = addressForm.querySelector("#address_street_one").value;
        const moveButton = locationSaveForm.querySelector(".move-location-button");
        const saveButton = locationSaveForm.querySelector(".save-address-button ");
        const locationID = locationSaveForm.querySelector("#bl_license_application_location_id");
        const locationKlass = locationSaveForm.querySelector("#bl_license_application_location_klass");
        locationID.value = document.querySelector(".address-form-area").querySelector('#address_id').value;
        locationKlass.value = "Address";
        moveButton.classList.remove('hidden');
        saveButton.classList.add('hidden');
        e.target.closest(".business-location-search-container").classList.add("hidden");
        moveButton.insertAdjacentHTML('beforebegin', "<p>Move to: " + addressLineOne) + "</p>";

    }

}
