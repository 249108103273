import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

let autoNumericList = [];

export default class extends Controller {

    connect() {
        console.log("log from tendered_fields controller");
        autoNumericList = new AutoNumeric.multiple(".tendered-field", ['NorthAmerican', {unformatOnSubmit: true}]);

    }


}
