import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from address_search controller");

    }

    showAddressForm(e) {
        const addressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        const searchResults = e.target.closest(".address-formset").parentNode.querySelector(".business-location-search-results");
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        if (addressIdField.value.length > 1) {
            fetch('/addresses/add_fields')
                .then(response => response.text())
                .then(html => {
                        addressForm.innerHTML = html;
                    }
                );
        }
        addressForm.classList.remove("hidden");
        searchResults.classList.add("hidden")
        addressIdField.value = "";
    }


    requestSearch(e) {
        clearTimeout(delayTimer);
        var component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = component.searchResultTarget;

            if (component.searchItemTarget.value.length > 2) {
                resultsArea.classList.remove("hidden")
                fetch('/search/address_query.html?search=' + encodeURIComponent(component.searchItemTarget.value) + "&type=internal_results")
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.querySelector("ul").innerHTML = html;
                        e.target.closest(".address-search-container").querySelector(".add-icon").classList.remove("hidden");
                    });
            } else {
                resultsArea.classList.add("hidden")
                e.target.closest(".address-search-container").querySelector(".add-icon").classList.remove("hidden");
            }

        }, 800)

    }

    selectAddress(e) {
        const searchResults = e.target.closest(".address-search-container").querySelector(".business-location-search-results");
        e.target.closest(".address-search-container").querySelector(".add-icon").classList.add("hidden");
        const addressId = e.currentTarget.id;
        const chosenAddress = e.currentTarget;
        const chosenAddressArea = e.target.closest(".address-formset").querySelector(".assigned-address-area");
        const newAddressForm = e.target.closest(".address-formset").querySelector(".address-form-area");
        console.log("click on " + addressId);
        //TODO: instead of looking for them and setting it locally, what if we sent the object and id to the controller and told it to update the address?
        const addressIdField = e.target.closest(".address-formset").parentNode.querySelector(".address-id")
        const parentKlass = 'ContactMethod';
        chosenAddressArea.innerHTML = "";
        chosenAddressArea.appendChild(chosenAddress);
        addressIdField.value = addressId;
        newAddressForm.innerHTML = "";
        searchResults.classList.add('hidden');


    }


}
