import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"

let numberElements;
let integerElements;

export default class extends Controller {

    connect() {
        console.log("log from bl-license-calculator controller");
        numberElements = new AutoNumeric.multiple([...document.querySelectorAll('.money-cell')], {
            currencySymbol: '$',
            unformatOnSubmit: true
        },);
        integerElements = new AutoNumeric.multiple([...document.querySelectorAll('.integer-cell')], {
            decimalPlaces: 0,
            unformatOnSubmit: true
        });
        let revenue = Number(AutoNumeric.getNumber(document.querySelector(".calc-input-primary")));
        let deductions = 0.0;
        const deductionsField = document.querySelector(".lic-total-deductions-field")
        if (deductionsField != null) {
            deductions = Number(AutoNumeric.getNumber(deductionsField));
        }
        if (revenue > 0 && deductions < revenue) {
            const formArea = document.querySelector(".fee-calc-form-parent");
            updatecalculationArea(formArea, false);
        }

    }

    updateRenewalAmountDue(event) {
        console.log("updating amount via blLicenseCalculator")
        const formArea = event.target.closest(".fee-calc-form-parent");
        updatecalculationArea(formArea, true);
    }

    updateLicenseCalcFields(event) {
        const formDom = event.target.closest('form');
        console.log("updating the calculation fields");
        let currentInputValue = 0.0
        if (AutoNumeric.isManagedByAutoNumeric(document.querySelector(".calc-input-primary"))) {
            currentInputValue = Number(AutoNumeric.getNumber(document.querySelector(".calc-input-primary")));
        } else {
            currentInputValue = document.querySelector(".calc-input-primary").value;
        }
        console.log("existing value is " + currentInputValue)
        const licenseID = event.target.value;
        console.log("new category is " + licenseID);
        let applicationId = document.URL.split('/')[4];
        if (applicationId == null) {
            applicationId = document.querySelector("#bl_license_application_id").value;
        }
        let licenseParam = "";
        if (applicationId.length > 0 && document.URL.split('/')[3] == "bl_license_applications") {
            licenseParam = "&business_license_application_id=" + applicationId
        }
        let amountParam = "&current_value=0"
        if (currentInputValue > 0) {
            amountParam = "&current_value=" + currentInputValue
        }
        fetch('/bl_license_applications/update_input_fields.html?license_type=' + licenseID + licenseParam + amountParam)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.querySelector(".calc-input-fields").innerHTML = html;
                document.querySelector(".calc-input-primary").value = currentInputValue;
                const formArea = event.target.closest(".fee-calc-form-parent");
                updatecalculationArea(formArea, this.element.querySelector(".add-charge-area"));
            })

    }

}

function updatecalculationArea(formArea, saveApplication) {
    const resultsArea = formArea.querySelector(".calc-results");
    const licenseTypeId = formArea.querySelector(".application-area").querySelector("#bl_license_application_license_type_id").value;
    let blLicenseApplicationId = formArea.querySelector(".application-area").querySelector("#bl_license_application_id")
    if (blLicenseApplicationId == null) {
        blLicenseApplicationId = document.querySelector("#bl_license_application_id");
    }
    let residencyQueryString = "";
    if (document.getElementById('bl_license_application_residency') != null) {
        residencyQueryString = "&residency=" + document.getElementById('bl_license_application_residency').checked;
    }
    let licenseExpirationParam = ""
    if (formArea.querySelector(".license-period-selector") != null) {
        licenseExpirationParam = "&license_expiration=" + formArea.querySelector(".license-period-selector").value;
    }
    formArea.querySelector(".feedback-area").innerHTML = "";
    if (licenseTypeId.length === 0) {
        console.log("fill in the license Type")
        formArea.querySelector(".feedback-area").innerHTML += '<p class="no-response feedback-text">License Type is missing!</p>';

    } else {
        let revenue = 0.0
        if (AutoNumeric.isManagedByAutoNumeric(document.querySelector(".calc-input-primary"))) {
            revenue = Number(AutoNumeric.getNumber(document.querySelector(".calc-input-primary")));
        } else {
            revenue = document.querySelector(".calc-input-primary").value;
        }
        let deductions = 0.0;
        const deductionsField = formArea.querySelector(".lic-total-deductions-field")
        if (deductionsField != null && deductionsField.value != null) {
            if (AutoNumeric.isManagedByAutoNumeric(formArea.querySelector(".lic-total-deductions-field"))) {
                deductions = Number(AutoNumeric.getNumber(deductionsField));
            } else {
                deductions = deductionsField.value;
            }
        }
        let currentUnits = revenue - deductions;
        let licenseParam = "";
        let blApplicationParam = "";
        if (deductions > revenue) {
            formArea.querySelector(".feedback-area").innerHTML += '<p class="deductions-too-high feedback-text">Deductions cannot exceed Revenues</p>';
        } else {
            if (formArea.querySelector(".deductions-too-high") !== null) {
                formArea.querySelector(".deductions-too-high").remove();
            }
            if (currentUnits >= 0) {
                if (licenseTypeId.length > 0 && Number(licenseTypeId) > 0) {
                    licenseParam = "&license_type_id=" + licenseTypeId +
                        residencyQueryString;
                }
                if (blLicenseApplicationId && blLicenseApplicationId.value.length > 0 && Number(blLicenseApplicationId.value) > 0) {
                    blApplicationParam = "&bl_license_application_id=" + blLicenseApplicationId.value +
                        residencyQueryString;
                }

                fetch('/bl_license_estimators/estimate_charge.html?units=' + currentUnits + licenseParam + blApplicationParam + licenseExpirationParam)
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        if (formArea.querySelector(".no-response") !== null) {
                            formArea.querySelector(".no-response").remove();
                        }
                        resultsArea.innerHTML = html;
                        const lineItemCharge = document.querySelector("#lineItemCharge");
                        const amountDue = document.querySelector("#amountDue");
                        if (lineItemCharge) {
                            amountDue.innerHTML = lineItemCharge.innerHTML;
                        }
                        const event = new Event("inner-connected")
                        const chargeButton = formArea.querySelector(".add-charge-area")
                        if (document.querySelector(".estimate-area") == null) {
                            chargeButton.classList.remove("hidden");
                        }
                        if (saveApplication) {
                            const localForm = amountDue.closest("form");
                            const localLicenseTypeField = localForm.querySelector("#bl_license_application_license_type_id");
                            if (localLicenseTypeField != null && localLicenseTypeField.value.length === 0) {
                                localLicenseTypeField.value = licenseTypeId;
                            }
                            amountDue.dispatchEvent(event)
                        }
                        if (!(AutoNumeric.isManagedByAutoNumeric(document.querySelector(".calc-input-primary")))) {
                            numberElements = new AutoNumeric.multiple([...document.querySelectorAll('.money-cell')], {
                                currencySymbol: '$',
                                unformatOnSubmit: true
                            },);
                            integerElements = new AutoNumeric.multiple([...document.querySelectorAll('.integer-cell')], {
                                decimalPlaces: 0,
                                unformatOnSubmit: true
                            });

                        }

                    });
            } else {
                formArea.querySelector(".feedback-area").innerHTML += '<p class="no-response feedback-text">Server Not Responding</p>';
            }
        }
    }


}
