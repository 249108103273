import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

let autoNumericList = [];

let paymentButton, stripe, card, terminal, selectedReader, paymentIntentId

export default class extends Controller {

    connect(e) {

        console.log("log from cr-cc-terminal controller");
        paymentButton = this.element.querySelector('.card-detail-button')
        paymentButton.disabled = true;
        paymentButton.innerText = 'Connecting to Reader'
        autoNumericList = new AutoNumeric.multiple([".surcharge-display", ".total-charge"], ['NorthAmerican', {unformatOnSubmit: true}]);
        terminal = StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: unexpectedDisconnect
        })
        updateCharges(this.element);
        discoverReaders()

    }

    connectReader(e) {
        e.preventDefault();
        e.target.disabled = true;
        e.target.innerText = "Activating..."
        paymentButton = this.element.querySelector('.terminal-connect-button')
        terminal.connectReader(selectedReader).then(function (connectResult) {
            if (connectResult.error) {
                console.log('connectReader Failed to connect: ', connectResult.error);
            } else {
                console.log('connectReader Connected to reader: ', connectResult.reader.label);
                e.target.closest(".cc-manual-fields").querySelector('#terminal-id').value = connectResult.reader.id;
                e.target.classList.add('hidden');
                paymentButton.classList.remove('hidden');
                setCartDescription(e.target)
            }
        });
    }

    sendChargeToTerminal(e) {
        e.preventDefault();
        const form = e.target.form;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log("update charge form post-creation");
                document.querySelector(".stripe-payment-form-area").innerHTML = data.html;
                document.querySelector(".card-detail-button").classList.add('hidden');
                document.querySelector(".terminal-connect-button").classList.add('hidden');
                e.target.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_amount_tendered").disabled = true;
                stripe = Stripe(e.target.closest(".cc-manual-fields").querySelector("#stripe-api-key").value);
                var elements = stripe.elements();
                var style = {
                    base: {
                        color: "#32325d",
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '24px',
                        backgroundColor: '#f7fbfd'
                    }
                };
                console.log("sendChargeToTerminal get payment_intent from from backend");
                const client_secret = e.target.closest(".cc-manual-fields").querySelector("#stripe-key").value;
                const resultsArea = e.target.closest(".cc-manual-fields").querySelector(".stripe-result");
                e.target.closest(".cc-manual-fields").querySelector("#stripe-submit").classList.add('hidden');
                terminal.collectPaymentMethod(client_secret).then(function (result) {
                    if (result.error) {
                        console.log('sendChargeToTerminal error', result);
                    } else {
                        console.log('sendChargeToTerminal terminal.collectPaymentMethod:', result.paymentIntent);
                        terminal.processPayment(result.paymentIntent).then(function (result) {
                            if (result.error) {
                                console.log('sendChargeToTerminal payment error:', result.error);
                                resultsArea.innerHTML = "<p class=\"alert\">" + result.error.message + "  Delete this payment line and add another.</p>";
                            } else if (result.paymentIntent) {
                                paymentIntentId = result.paymentIntent.id;
                                console.log('sendChargeToTerminal terminal.processPayment', result.paymentIntent);
                                if (result.paymentIntent.status === 'requires_capture') {
                                    fetch('/cash_receipting/cc_terminal_payments/capture_payment', {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({"id": paymentIntentId})
                                    })
                                        .then(function (response) {
                                            return response.json();
                                        })
                                        .then(function (data) {
                                            if (result.error) {
                                                resultsArea.innerHTML = "<p class=\"alert\">" + result.error.message + "</p>";
                                                e.target.closest(".cc-manual-fields").querySelector(".terminal-connect-button").disabled = false;
                                                e.target.closest(".cc-manual-fields").querySelector(".terminal-connect-button").innerText = 'Reprocess';
                                            } else {
                                                console.log('sendChargeToTerminal payment captured', data);
                                                e.target.closest(".cc-manual-fields").querySelector(".terminal-connect-button").classList.add('hidden');
                                                resultsArea.innerHTML = "<p class=\"notice\"> Payment Successful</p>";
                                                e.target.closest(".cc-manual-fields").querySelector(".stripe-payment-form-area").classList.add('hidden');
                                                e.target.closest(".cc-manual-fields").querySelector(".ready-to-pay").innerText = "true";
                                                e.target.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_reference").value = result.paymentIntent.id;
                                                e.target.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_amount_tendered").disabled = true;
                                                e.target.closest(".payment-line").querySelector(".button--dropdown").disabled = true;
                                            }
                                        });

                                } else {
                                    resultsArea.innerHTML = "<p class=\"alert\">" + result.message + "</p>";

                                }
                            }
                        });
                    }
                });
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set card payment. Please try again.');
            });

    }


    updateChargeAmount(e) {
        updateCharges(e.target)
    }

}

function makeNumber(textVal) {
    return Number(textVal.replace("$", "").replace(",", "").trim());
}

function fetchConnectionToken() {
    return fetch('/stripe_terminals/connection_token', {method: "GET"})
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            return data.secret;
        });
}

function discoverReaders() {
    const preferredReader = document.getElementById('cc_machine')
    const config = {simulated: false}
    const creditCardFields = document.querySelector(".cc-manual-fields")
    const resultsArea = creditCardFields.querySelector(".stripe-result");

    let readers
    terminal.discoverReaders(config)
        .then(function (discoverResult) {
            if (discoverResult.error) {
                console.log('Failed to discover: ', discoverResult.error);
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.log('No available readers.', discoverResult);
                resultsArea.innerHTML = "<p class=\"alert\">No available readers; please choose a different payment method.</p>";
            } else {
                // You should show the list of discoveredReaders to the
                // cashier here and let them select which to connect to (see below).
                console.log('168', discoverResult)
                readers = discoverResult.discoveredReaders.filter(function (terminal) {
                    return preferredReader.value === terminal.id
                })
                console.log('172', readers)
            if (readers.length > 0) {
                selectedReader = readers[0];
                paymentButton.disabled = false;
                paymentButton.innerText = 'Activate Reader'
            }
        }
    });
}

function unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log("Disconnected from reader")
}

function setCartDescription(target) {
    const cartId = location.pathname.match(/\d{1,30}/);
    const amountTendered = Math.trunc(makeNumber(target.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_amount_tendered").value) * 100);
    const amountSurcharge = Math.trunc(makeNumber(target.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_surcharge_amount").value) * 100);
    fetch('/carts/' + cartId, {method: "GET"})
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            let cartInfo = Object.assign({}, data);
            cartInfo.cart.total = amountTendered + amountSurcharge;
            cartInfo.cart.line_items.push({description: "Today's Payment:", amount: amountTendered, quantity: 1});
            cartInfo.cart.line_items.push({description: "CC Surcharge:", amount: amountSurcharge, quantity: 1});
            console.log("setCartDescription cartInfo", cartInfo);
            terminal.setReaderDisplay(cartInfo)
        });
}

function updateCharges(line) {
    console.log("Charge Amount updated, updating surcharge");
    const surchargeDisplay = line.closest(".cc-manual-fields").querySelector(".surcharge-display");
    const totalDisplay = line.closest(".cc-manual-fields").querySelector(".total-charge");
    const base = makeNumber(line.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_surcharge_base").value);
    const rate = makeNumber(line.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_surcharge_rate").value);
    const newAmountTendered = makeNumber(line.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_amount_tendered").value);
    const newSurcharge = Math.round((newAmountTendered * (rate) + base) * 100) / 100;
    const newTotal = Math.round((newAmountTendered * (1 + rate) + base) * 100) / 100;
    line.closest(".cc-manual-fields").querySelector("#cash_receipting_cc_terminal_payment_surcharge_amount").value = newSurcharge;
    surchargeDisplay.innerText = newSurcharge;
    totalDisplay.innerText = newTotal;
    autoNumericList.reformat;
}
