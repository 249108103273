import { Controller } from "stimulus"

export default class extends Controller {
    connect(){
        console.log("connect from monthSelectController")

    }

    filterMonth(event){
        console.log("Month Changed!")
        const resultsList = document.querySelector(".results-list")
        resultsList.innerHTML=''
        window.location.href='?month='+ event.target.value

    }


}
