import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from tasks controller");

    }

    getDelayedReport(e) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            reloadReport();
        }, 2100)

    }


    getReport(e) {
        reloadReport();
    }
}

function reloadReport() {
    const active = document.getElementById('active').checked;
    const unassigned = document.getElementById('unassigned').checked;
    const selectedAssignees = Array.from(document.getElementById('assignees')
        .querySelectorAll(':checked')).map(option => option.value)
    console.log("finding tasks for report ");
    window.location.href = "/tasks?active=" + active +
        "&unassigned=" + unassigned + '&assignees=' + encodeURIComponent(selectedAssignees);
}
