import {Controller} from "stimulus"
import Rails from '@rails/ujs';

let currentSlideId = "1";
let isNavigating = false;

export default class extends Controller {
    static get targets() {
        return ["businessForm", "lineItemClass", "primaryContactForm"]
    }


    connect() {
        console.log("log from bl-internal-license-wizard controller");
        currentSlideId = "1";
        hideUnneededLicenseApplicationFields();
    }

    previousSlide(e) {
        if (isNavigating) return;
        isNavigating = true;
        goToPreviousSlide();
        setTimeout(() => {
            isNavigating = false;
        }, 750); // 500ms delay
        document.getElementById("flash-area").innerHTML = "" // clear flash messages
    }


    nextSlide(e) {
        if (isNavigating) return;
        isNavigating = true;
        document.getElementById("slide-" + currentSlideId).classList.add("hidden");
        document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.remove("selected")
        currentSlideId = String(Number(currentSlideId) + 1);
        document.getElementById("slide-" + currentSlideId).classList.remove("hidden");
        document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.add("selected")
        setTimeout(() => {
            isNavigating = false;
        }, 750); // 500ms delay
        document.getElementById("flash-area").innerHTML = "" // clear flash messages

    }

    selectClassification(e) {
        const classificationTypeId = e.currentTarget.id;
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        classificationField.value = classificationTypeId;
        slideOneReady();
    }

    setClassification(e) {
        // move html element with id bl_license_application_classification_id to inside the form inside the div with the class 'application-form'
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        const licenseForm = document.querySelector(".application-form").querySelector("form");
        licenseForm.appendChild(classificationField);

    }


    selectLicenseType(e) {
        const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
        const licenseTypeId = e.currentTarget.id;
        licenseTypeField.value = licenseTypeId;
    }

    updateWizardSteps(e) {
        const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
        const licenseTypeId = licenseTypeField.value;
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        const classificationTypeId = classificationField.value;
        document.querySelector(".slide-controls-1").classList.add('hidden');
        //load subsequent wizard steps
        //fetch wizard_steps from licensing_wizard_steps_controller
        fetch('/licensing/wizard_steps.html?license_type_id=' + licenseTypeId + '&classification_id=' + classificationTypeId, {
            method: 'GET'
        }).then(response => response.text())
            .then(data => {
                const dataBlock = JSON.parse(data);
                console.log(dataBlock);
                e.target.closest(".wizard").querySelector("#wizard-nav").innerHTML = dataBlock['nav'];
                e.target.closest(".wizard-steps").innerHTML = dataBlock['html'];
                const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
                licenseTypeField.value = licenseTypeId;
                hideUnneededLicenseApplicationFields();
                slideOneReady();
            });
    }

    createBusiness(e) {
        document.getElementById('bl_license_application_residency').checked = document.getElementById('business_business_is_local').checked;
        // Select the form using this.element (the controller's root element)
        const form = this.businessFormTarget;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log('Form submission successful:', data);
                console.log("Setting Business ID in Location");
                const businessIdField = document.querySelector("#business_location_business_id");
                businessIdField.value = data.business.id
                document.querySelector(".business-form").innerHTML = data.form_partial;
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set business. Please try again.');
            });
    }

    setBusinessInfo(e) {
        console.log("Setting Business ID in Location");
        const businessIdField = document.querySelector("#business_location_business_id");
        businessIdField.value = JSON.parse(e.detail[2].response).business['id'];
        document.querySelector(".business-form").innerHTML = JSON.parse(e.detail[2].response).form_partial;
    }


    slideOneReady() {
        slideOneReady();
    }

    slideTwoReady() {
        slideTwoReady();
    }

    slideFourReady() {
        let readyState = false
        const useLocationCheckbox = document.getElementById('location_is_contact').checked
        if (useLocationCheckbox) {
            readyState = true;
        } else {
            readyState = Array.from(document.querySelectorAll(".contact-submittable")).map(field => {
                return field.value === 'true'
            }).every(Boolean)
        }
        if (readyState) {
            document.querySelector(".slide-next-4").classList.remove('hidden');
        } else {
            document.querySelector(".slide-next-4").classList.add('hidden');
        }

    }

    selectAddress(e) {
        setTimeout(function () {
            slideThreeReady();
        }, 1000);
    }

    showContactForm(e) {
        const contactForm = document.querySelector(".primary_contact_form");
        if (document.getElementById('location_is_contact').checked) {
            contactForm.classList.add('hidden')
        } else {
            contactForm.classList.remove('hidden')
        }
    }


    setBusinessLocation(event) {
        event.preventDefault(); // Prevent default form submission

        // Select the form using this.element (the controller's root element)
        const form = event.target.form;

        // Get the CSRF token from the meta tag
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        // Create a FormData object from the form
        const formData = new FormData(form);

        // Submit the form using Fetch API
        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.errors || 'Unknown error occurred');
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log('Form submission successful:', data);
                const licenseApplicationForm = document.querySelector(".bl-license-application-form");
                const licensableIdField = licenseApplicationForm.querySelector("#bl_license_application_licensable_id");
                const licensableTypeField = licenseApplicationForm.querySelector("#bl_license_application_licensable_type");
                const licensableId = data.licensable_id;
                const licensableType = data.licensable_type;
                if (licensableIdField) {
                    console.log("setting Location from save");
                    licensableIdField.value = licensableId;
                    licensableTypeField.value = licensableType;
                    slideThreeReady();
                    document.querySelector(".wizard-licensable-submit").classList.add('hidden');
                    document.getElementById("flash-area").innerHTML = "<p class='notice'> Business Location Set</p>"

                } else {
                    {
                        document.getElementById("flash-area").innerHTML = "<p class='alert'> Sorry! The Business Location Was Not Set Properly; please search and try again</p>"
                        goToPreviousSlide();
                    }
                }
            })
            .catch(error => {
                console.error('Error during form submission:', error);
                alert('Failed to set business location. Please try again.');
            });


    }

    // tied to 'Next' button on slide four, business location contact information, saves the contact info
    createPrimaryContactInfo(e) {
        const businessIdField = window.business_id;
        if (document.getElementById('location_is_contact').checked == false) {
            const contactRelationForm = this.primaryContactFormTarget;
            contactRelationForm.querySelector("#contact_relation_contactable_id").value = businessIdField.value;
            contactRelationForm.querySelector("#contact_relation_contactable_type").value = 'Business';
            // Get the CSRF token from the meta tag
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            // Create a FormData object from the form
            const formData = new FormData(contactRelationForm);

            // Submit the form using Fetch API
            fetch(contactRelationForm.action, {
                method: contactRelationForm.method,
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Accept': 'application/json',
                },
                credentials: 'same-origin',
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(data => {
                            throw new Error(data.errors || 'Unknown error occurred');
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Form submission successful:', data);
                })
                .catch(error => {
                    console.error('Error during form submission:', error);
                    alert('Failed to set business location. Please try again.');
                });
        } else {
            fetch('/businesses/' + businessIdField.value + '/contact_info_set_to_location')
                .then(response => {
                    return response.text();
                })
                .then(html => {
                })
        }

    }

    setTaskTypes(e) {
        e.preventDefault();
        console.log('Adding tasks');
        const taskType = e.target.dataset.id;
        const taskDescription = e.target.dataset.description
        const applicationTaskTypeField = document.getElementById("bl_license_application_task_type_ids")
        e.target.closest('li').classList.toggle('selected');
        const currentTasksFields = e.target.closest('.add-subset').querySelectorAll('.selected')
        applicationTaskTypeField.value = Array.from(currentTasksFields).map(e => {
            return e.children[0].dataset.id
        })
        document.getElementById("flash-area").innerHTML = `<p class="notice">Task Added: ${taskDescription}</p>`;
    }


}

function hideUnneededLicenseApplicationFields() {
    // document.querySelector(".select-license-type-by-classification").classList.add('hidden')
}

function goToPreviousSlide() {
    document.getElementById("slide-" + currentSlideId).classList.add("hidden");
    document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.remove("selected")
    currentSlideId = String(Number(currentSlideId) - 1);
    document.getElementById("slide-" + currentSlideId).classList.remove("hidden");
    document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.add("selected")
}

function slideOneReady() {
    const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
    const checkClassification = document.getElementById('require-classification');
    const classificationField = document.getElementById("bl_license_application_classification_id");
    if (licenseTypeField == null || licenseTypeField.value.length == 0 && (checkClassification != null && classificationField.value.length == 0)) {
        document.querySelector(".slide-controls-1").classList.add('hidden');
    } else {
        document.querySelector(".slide-controls-1").classList.remove('hidden');
    }
}


function slideTwoReady() {
    console.log('slide 2 ready?')
    const businessNameField = window.business_business_name;
    if (businessNameField.value.length == 0) {
        document.querySelector(".slide-next-2").classList.add('hidden');
    } else {
        document.querySelector(".slide-next-2").classList.remove('hidden');
    }
}


function slideThreeReady() {
    console.log('slide 3 ready?')
    const licenseApplicationForm = document.querySelector(".bl-license-application-form");
    const licensableIdField = licenseApplicationForm.querySelector("#bl_license_application_licensable_id");
    if (licensableIdField.value.length == 0) {
        document.querySelector(".slide-next-3").classList.add('hidden');
    } else {
        document.querySelector(".slide-next-3").classList.remove('hidden');
    }

}


