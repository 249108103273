import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("utilityBackflowForm");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const notice = urlParams.get('notice');
        if (!(Object.is(notice, null) || Object.is(notice, undefined)) && notice.length > 0) {
            const textNode = document.createElement("P")
            textNode.classList.add("notice")
            textNode.innerText = notice
            document.getElementById('flash-area').appendChild(textNode)
        }
    }

    testerSelected(event) {
        event.target.parentElement.parentElement.querySelector("ul").innerHTML = "";
    }

    calendarFocused(event) {
        event.target.parentElement.parentElement.parentElement.querySelector("ul").innerHTML = "";
    }


    putCertificationSuccess(event) {
        console.log("update_posted")
    }

    closeForm(event) {
        event.target.closest(".quick-form-area").classList.remove("editing");

        console.log("Closed?");

    }


}

