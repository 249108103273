import {Controller} from "stimulus"
import {contactFormSubmittable} from "../../../lib/assets/form-validation";

var delayTimer;
export default class extends Controller {
    static targets = ["searchResult", "searchItem"];

    connect() {
        console.log("log from business-select controller");
    }

    showBusinessForm(e) {
        e.preventDefault();
        const businessFormArea = e.target.closest(".business-select-area").querySelector(".business-fields")
        businessFormArea.classList.remove("hidden");
        e.target.closest(".add-business-control").classList.add("hidden");
    }

    requestSearch(e) {
        if (e.type === 'click') {
            return;
        }
        clearTimeout(delayTimer);
        let component = this;
        const resultsArea = component.searchResultTarget;
        const contractorsOnly = document.getElementById("contractors_only").checked
        let active;
        if (!(Object.is(document.getElementById("active"), null))) {
            active = document.getElementById("active").checked
        }
        const newBusinessControl = e.target.closest(".business-select-area").querySelector(".add-business-control")
        const excluded = document.getElementById("business_excluded_ids").value
        const active_only = document.getElementById("active_only").value
        delayTimer = setTimeout(function () {
            if (component.searchItemTarget.value.length > 2) {
                let url = '/search/business_query.html?search=' +
                    encodeURIComponent(component.searchItemTarget.value)
                if (!(Object.is(contractorsOnly, null) || Object.is(contractorsOnly, undefined))) {
                    url += '&contractors_only=' + encodeURIComponent(contractorsOnly)
                }
                if (!(Object.is(active, null) || Object.is(active, undefined))) {
                    url += '&active=' + encodeURIComponent(active)
                }
                if (!(Object.is(excluded, null) || Object.is(excluded, undefined))) {
                    url += '&excluded_ids=' + encodeURIComponent(excluded)
                }

                resultsArea.classList.add("showing");
                fetch(url)
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.querySelector("ul").innerHTML = html;
                        if (!!newBusinessControl) {
                            newBusinessControl.classList.remove("hidden");
                        }
                    });
            } else {
                resultsArea.classList.remove("showing")
            }

        }, 800)

    }

    selectBusiness(e) {
        e.preventDefault();
        const chosenBusinessId = e.target.closest('a').id;
        const businessFormArea = e.target.closest(".business-select-area").querySelector(".new-business-form")
        console.log("clicked on business" + chosenBusinessId)
        e.target.closest(".business-select-area").querySelector(".bl-search-results").classList.remove("showing");
        fetch("/businesses/" + encodeURIComponent(chosenBusinessId) + "/render_card")
            .then(response => {
                return response.text();
            })
            .then(html => {
                businessFormArea.innerHTML = html;
                businessFormArea.classList.remove("hidden");
                e.target.closest(".business-search-form").querySelector(".bl-search-results").classList.remove("showing");
                contactFormSubmittable();
            })
    }

    selectBusinessAlias(e) {
        e.preventDefault();
        const chosenBusinessAliasId = e.target.closest('a').id;
        const businessFormArea = e.target.closest(".business-select-area").querySelector(".new-business-form")
        console.log("clicked on business" + chosenBusinessAliasId)
        e.target.closest(".business-select-area").querySelector(".bl-search-results").classList.remove("showing");
        fetch("/business_aliases/" + encodeURIComponent(chosenBusinessAliasId) + "/render_card")
            .then(response => {
                return response.text();
            })
            .then(html => {
                businessFormArea.innerHTML = html;
                businessFormArea.classList.remove("hidden");
                contactFormSubmittable();
            })
    }
}
