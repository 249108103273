import {Controller} from "stimulus"

let amountField;
var autocomplete = require('autocompleter');
//fuzzy search for autocomplete
import Fuse from 'fuse.js';

export default class extends Controller {

    connect() {
        console.log("log from ar-deposits-entries controller");
        amountField = document.querySelector("#ar_deposit_entry_amount");
        const autocompleteInput = document.getElementById("ar_deposit_entry_payment_source");
        const bankList = JSON.parse(document.getElementById('banks').value)
        const fuseOptions = {
            includeScore: true,
            keys: ['label']
        }
        const fuse = new Fuse(bankList, fuseOptions)
        autocomplete({
            input: autocompleteInput,
            fetch: function (text, update) {
                text = text.toLowerCase();
                let suggestions = fuse.search(text);
                update(suggestions.map(a => a.item));

            },
            onSelect: function (item) {
                autocompleteInput.value = item.label;
            },
            render: function (item, currentValue) {
                var div = document.createElement("div");
                div.textContent = item.label;
                div.classList.add('dropdown__option')
                return div;
            },
            emptyMsg: "No banks found",
            className: 'dropdown__option-wrapper dropdown__option-wrapper--visible'
        })
    }

    refresh() {
    }

    displayAddlInfo(e) {
        console.log("payment type changed");
        if (e.target.innerText === 'Check') {
            console.log("display check info");
            e.target.closest(".ar-dep-entry-form").querySelector(".ar-dep-entry-optional-info-line").classList.remove("hidden")
        } else {
            console.log("do not display check info");
            e.target.closest(".ar-dep-entry-form").querySelector(".ar-dep-entry-optional-info-line").classList.add("hidden")
        }

    }

    removeMe(e) {
        console.log("successful deletion")
        const removalRow = e.target.closest(".ar-entered-row");
        removalRow.parentNode.removeChild(removalRow);
    }


    addEntryButtonState(e) {
        console.log('checking ar-deposit-entries form readiness')
        const formArea = e.target.closest(".ar-dep-entry-form");
        const paymentType = formArea.querySelector("#ar_deposit_entry_entry_type").value;
        const paymentTypePresent = paymentType.length > 0;
        const lineAmountPresent = makeNumber(amountField.value);
        const ardeSubmitButton = formArea.querySelector(".deposit-entry-submit");
        let checkFields = true;
        if (paymentType === 'Check') {
            const bankPresent = formArea.querySelector("#ar_deposit_entry_payment_source").value.length > 0;
            const payorPresent = formArea.querySelector("#ar_deposit_entry_payor").value.length > 0;
            const referencePresent = formArea.querySelector("#ar_deposit_entry_reference_string").value.length > 0;
            checkFields = (bankPresent && payorPresent && referencePresent)
        }
        ardeSubmitButton.disabled = !(paymentTypePresent && lineAmountPresent && checkFields);
    }


}

function makeNumber(textVal) {
    return Number(textVal.replace("$", "").replace(",", "").trim());
}
