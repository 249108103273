import {Controller} from "stimulus"
import {validateFields} from "../../../lib/assets/form-validation";

let delayTimer;
export default class extends Controller {

    connect() {
        console.log("hello from business-location-form");
    }

    validateForm(e) {
        const form = e.target.closest('.business-location-form');
        const submitButton = form.querySelector('input[type="submit"]');
        if (submitButton) {
            delayTimer = setTimeout(function () {
                validateFields(form);
                const valid = form.querySelector('.business-location-form-submittable').value === 'true';
                if (valid) {
                    submitButton.disabled = false;
                } else {
                    submitButton.disabled = true;
                }
            }, 800)
        }
    }
}