import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("popupFormBehavior");
    }

    openForm(event) {
        event.target.closest(".quick-form-area").classList.add("editing");

        console.log("Closed?");

    }


}
