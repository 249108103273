import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("tableManager controller connected");

    }

    removeRow(e) {
        console.log("successful deletion")
        const removalRow = e.target.closest(".removable-row");
        removalRow.parentNode.removeChild(removalRow);
    }


}

function labelDisplayerEvent(inputEvent) {
}