import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("log from property tax records index controller");
    }


    updateReportFilter(e) {
        console.log("updating_report")
        const year = document.getElementById('year').value;
        window.location.href = (window.location.pathname + window.location.search).replace(/\?year=(All|\d*)/, '') + '?year=' + year;

    }


}
