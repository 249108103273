import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from reportsCashflowMonthlyPareto");
    }

    updateReport(e) {
        console.log("report changed")
        const reportYear = document.getElementById("year")
        const reportMonth = document.getElementById("month")
        const title = "Monthly " + this.data.get('menuLabel') + " Pareto: " + reportMonth.value + "/" + reportYear.value;
        window.location.href = 'chart?title=' + title + '&data_source=' + this.data.get('url') + '&year=' + reportYear.value + '&month=' + reportMonth.value;
    }
}