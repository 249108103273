import {Controller} from "stimulus"

let draggable
export default class extends Controller {

    connect() {
        console.log("hello from inspectionDayPlanner");
    }

    updateReport(e) {
        console.log("report changed");
        const reportDate = document.getElementById("for_date")
        window.location.href = '?for_date=' + reportDate.value;
    }

    dragInspection(e) {
        console.log("dragging inspection");
        e.target.classList.add("opacity-30")
        draggable = e.target
    }

    touchInspection(e) {
        console.log("touching inspection");
        e.preventDefault();
        draggable = e.target.closest(".inspection-visit")
        document.addEventListener('touchmove', handleTouchMove);
    }

    dragStopInspection(e) {
        console.log("done dragging inspection");
        e.target.classList.remove("opacity-30")
        document.removeEventListener('touchmove', handleTouchMove);
    }

    onAssignable(e) {
        e.preventDefault();
        console.log('over an assignable timeslot');
        return true;
    }

    visitAssignable(e) {
        console.log("can assign to timeslot")
        e.target.classList.add("animate-pulse")
        setTimeout(function () {
            e.target.classList.remove("animate-pulse");
        }, 3000);
        return true;
    }

    leaveAssignable(e) {
        console.log("left assignable timeslot")
        e.target.classList.remove("animate-pulse")

    }


    assignTouchedInspection(e) {
        e.preventDefault();
        console.log("assigning to inspector")
        const touch = e.changedTouches[0];
        const lastTouchedElement = document.elementFromPoint(touch.clientX, touch.clientY);
        document.removeEventListener('touchmove', handleTouchMove);
        if (lastTouchedElement.closest(".timeslot")) {
            lastTouchedElement.closest(".timeslot").querySelector(".inspections-area").prepend(draggable);
            const timeslot = lastTouchedElement.closest(".timeslot").dataset.time;
            const inspectionId = draggable.dataset.inspectionId;
            const inspectionVisitId = draggable.dataset.inspectionVisitId;
            updateInspectionTimeSlot(timeslot, inspectionId, inspectionVisitId);
            draggable.classList.remove("opacity-30");
            draggable.classList.add("animate-pulse");
            setTimeout(function () {
                draggable.classList.remove("animate-pulse");
            }, 1000);
        }

    }

    assignInspection(e) {
        e.preventDefault();
        console.log("assigning to inspector")
        const timeslot = e.target.closest(".timeslot").dataset.time;
        const inspectionId = draggable.dataset.inspectionId;
        const inspectionVisitId = draggable.dataset.inspectionVisitId;
        updateInspectionTimeSlot(timeslot, inspectionId, inspectionVisitId);
        e.target.closest(".timeslot").querySelector(".inspections-area").prepend(draggable);
        draggable.classList.remove("opacity-30");
        draggable.classList.add("animate-pulse");
        setTimeout(function () {
            draggable.classList.remove("animate-pulse");
        }, 1000);
    }
}

function updateInspectionTimeSlot(timeslot, inspectionId, inspectionVisitId) {
    console.log("updating inspection visit");
    fetch('/inspections/' + inspectionId + '/inspection_visits/' + inspectionVisitId, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({scheduled_for: timeslot})
    }).then(response => response.json())
        .then(data => {
            console.log(data);
            document.querySelectorAll(".inspector-column").forEach((inspector) => {
                //get count of .inspection-visit
                const count = inspector.querySelectorAll(".inspection-visit").length;
                inspector.querySelector(".inspector-count").innerHTML = count;
            });
        });
}

function handleTouchMove(e) {
    // Get the height of the viewport
    const viewportHeight = window.innerHeight;

    // Define the distance from the bottom of the viewport that will trigger scrolling
    const scrollTriggerDistance = 100; // Adjust this value as needed

    // Get the touch position from the touch event
    const touch = e.touches[0];

    // Check if the touch is within scrollTriggerDistance pixels from the bottom of the viewport
    if (touch.clientY > viewportHeight - scrollTriggerDistance) {
        // Scroll the document down by a certain amount (e.g., 10 pixels)
        document.documentElement.scrollTop += 10;
        document.body.scrollTop += 10; // For Safari
    }

    // Check if the touch is within scrollTriggerDistance pixels from the top of the viewport
    if (touch.clientY < scrollTriggerDistance) {
        // Scroll the document up by a certain amount (e.g., 10 pixels)
        document.documentElement.scrollTop -= 10;
        document.body.scrollTop -= 10; // For Safari
    }
}