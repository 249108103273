import {Controller} from "stimulus"


export default class extends Controller {

    connect() {
        console.log("log from license-types-calculator controller");
        document.getElementById("bl_license_application_business_purpose").classList.add('hidden');
        document.getElementById("bl_license_application_limitations").classList.add('hidden');
        document.querySelector(".residency-checkbox").classList.remove('hidden');
        document.querySelector("add-charge-area").classList.add('hidden');
    }


}


