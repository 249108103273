import {Controller} from "stimulus";

let certsToEmail = [];
export default class extends Controller {

    connect() {
    }

    manageEmailList(e) {
        if (e.target.checked) {
            certsToEmail.push(e.target.value).uniq
        } else {
            certsToEmail = certsToEmail.filter(id => id != e.target.value)
        }
        if (certsToEmail.length > 0) {
            document.querySelector(".email-receipt").classList.remove('invisible')
        } else {
            document.querySelector(".email-receipt").classList.add('invisible')

        }
    }

    selectAll(e) {
        const allCheckboxes = document.querySelectorAll(".ubd-checkbox")
        if (e.target.checked) {
            certsToEmail = Array.prototype.slice.call(allCheckboxes).map(checkMark => checkMark.value)
            Array.prototype.slice.call(allCheckboxes).map(checkMark => checkMark.checked = true)
            console.log(certsToEmail)
        } else {
            certsToEmail = []
            Array.prototype.slice.call(allCheckboxes).map(checkMark => checkMark.checked = false)
            console.log(certsToEmail)
        }
        if (certsToEmail.length > 0) {
            document.querySelector(".email-receipt").classList.remove('invisible')
        } else {
            document.querySelector(".email-receipt").classList.add('invisible')

        }
    }

    enableButtonOnEmail(e) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const button = document.querySelector("#send-email-button")
        if (emailRegex.test(String(e.target.value.toLowerCase()))) {
            button.disabled = -false
        } else {
            button.disabled = true;
        }
    }

    sendForms(e) {
        const emailAddress = document.querySelector('#dynamic_recipient').value
        const flashArea = document.getElementById('flash-area');
        const allCheckboxes = document.querySelectorAll(".ubd-checkbox")
        fetch('/utilities_device_certifications/email_forms.html?ids=' + certsToEmail + '&email=' + emailAddress)
            .then(response => {
                return response.text();
            })
            .then(html => {
                Array.prototype.slice.call(allCheckboxes).map(checkMark => checkMark.checked = false)
                flashArea.innerHTML = "<p class=\"notice\">" + "Forms sent to " + emailAddress + "</p>";
                flashArea.scrollIntoView();
            })
            .catch((error) => {
                console.warn(error);
                flashArea.innerHTML = "<p class=\"notice\">" + error.message + "</p>";
                flashArea.scrollIntoView();
            })
        ;
    }
}
