import { Controller } from "stimulus"
var delayTimer;
export default class extends Controller {
  static targets = [ "locationPointId", "locationPointType", "locationPointSearchField" ];
  connect() {
    console.log("log from locationSearch controller");

  }

  requestSearch(){
      clearTimeout(delayTimer);
      var component = this;
      delayTimer = setTimeout(function () {
          const searchArea = component.targets.scope.element.querySelector(".locationSearchInput")
          const resultsArea = component.targets.scope.element.querySelector("ul")
          if (searchArea.value.length > 0){
              resultsArea.classList.add("showing")
              fetch('/search/location_query.html?search='+ searchArea.value)
                  .then(response=> {
                      return response.text();
                  })
                  .then(html=> {
                      resultsArea.innerHTML=html;
                  });
          }else{
              resultsArea.classList.remove("showing")
          }

      }, 500)

  }

  selectLocation(itemClicked){
      const resultsArea = this.targets.scope.element.querySelector("ul")
      const locationElement = itemClicked.target.closest("li");
      this.locationPointSearchFieldTarget.value = locationElement.innerText;
      this.locationPointIdTarget.value = locationElement["id"];
      this.locationPointTypeTarget.value = locationElement["type"]
      console.log("selected this" + locationElement );
      resultsArea.classList.remove("showing")

  }
}
