import {Controller} from "stimulus"
import Honeybadger from 'honeybadger-js';

let delayTimer, previousText;
export default class extends Controller {
    static targets = ["searchResults", "searchItem"];

    connect() {
        console.log("log from classification_search controller");

    }

    requestSearch() {
        clearTimeout(delayTimer);
        var component = this;
        delayTimer = setTimeout(function () {
            const resultsArea = document.querySelector('.classification-search-results')
            if (component.searchItemTarget.value.length > 0) {
                resultsArea.classList.remove("hidden")
                fetch('/classifications/query.html?search=' + encodeURIComponent(component.searchItemTarget.value))
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.innerHTML = html;
                    }).catch(error => {
                    console.warn(error);
                    Honeybadger.notify(error)
                    document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
                });
            } else {
                resultsArea.classList.add("hidden")
            }

        }, 800)

    }


    selectClassification(e) {
        const searchResults = document.querySelector(".classification-search-results");
        const licenseTypeArea = document.querySelector(".license-type-selection");
        const classificationTypeId = e.currentTarget.id;
        const licenseTypes = e.currentTarget.querySelector(".license-type-results");
        const chosenClassification = e.currentTarget.querySelector(".chosen-classification");
        const chosenClassificationArea = document.querySelector(".classification-selection");
        console.log("click on " + classificationTypeId);
        const classificationField = document.querySelector("#classification_id");

        chosenClassificationArea.innerHTML = "";
        chosenClassificationArea.appendChild(chosenClassification);
        chosenClassification.classList.remove('hidden');
        licenseTypeArea.innerHTML = "";
        licenseTypeArea.appendChild(licenseTypes);
        licenseTypes.classList.remove('hidden');
        classificationField.value = classificationTypeId;
        searchResults.classList.add('hidden');
        if (licenseTypes.querySelectorAll(".selectable-result").length == 1) {
            let event = new Event('click');
            licenseTypes.querySelector(".selectable-result").dispatchEvent(event);
        }

    }


    selectLicenseType(e) {
        const licenseTypeId = e.currentTarget.id;
        const localLicenseTypeEmbed = e.currentTarget.closest(".classification-search-container").querySelector("#license_type_id")
        localLicenseTypeEmbed.value = licenseTypeId
        Array.from(e.currentTarget.closest(".license-type-results").querySelectorAll(".license-type")).map(function (selection) {
            selection.classList.remove('selected');
        })
        e.currentTarget.classList.add('selected');
    }

    showHelperText(e) {
        setTimeout(function () {
            if (previousText) {
                previousText.classList.remove('editing');
            }
            const helperText = e.target.closest(".result-location").querySelector(".result-helper");
            const windowHeight = window.innerHeight;
            const helperTextHeight = helperText.innerText.length / 40
            window.innerHeight = windowHeight + helperTextHeight
            helperText.classList.add('editing');
            previousText = helperText;
        }, 250);
    }

    hideHelperText(e) {
        setTimeout(function () {
        }, 250);
    }

}
