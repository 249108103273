import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        // console.log("connect from fieldBehaviorController");
        const inputs = this.element.querySelectorAll('input, select, checkbox');
        Array.from(inputs).map(function (inputElement) {
            if (!!inputElement) {
                if (!!inputElement.labels && inputElement.labels.length > 0) {
                    labelDisplayer(inputElement)

                }
            }
        })

    }

    manageLabel(event) {
        labelDisplayer(event.target);
    }

    preventEnter(e) {
        const code = e.keyCode || e.which;
        if (code == 13) {
            e.preventDefault();
            return false;
        }
    }


}

function labelDisplayer(inputElement) {
    if (document.body.contains(inputElement)) {
        if (inputElement.value != "" || inputElement.classList.contains('input')) {
            inputElement.classList.add('label-showing');
            inputElement.labels[0].classList.add('label-showing');
            inputElement.classList.remove('label-hidden');
            inputElement.labels[0].classList.remove('label-hidden');
        } else {
            inputElement.classList.remove('label-showing');
            inputElement.labels[0].classList.remove('label-showing');
            inputElement.classList.add('label-hidden');
            inputElement.labels[0].classList.add('label-hidden');

        }
    }
}